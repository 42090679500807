// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modals {
    position: absolute;
    top: 50px;
    left: 0px;
    width: 100%;
    height: 66%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    font-family: 'Poppins';
    font-weight: 400;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  
  .modal-content input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modal-content button {
    padding: 8px 16px;
    margin: 5px;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }
  
  
  
  .error {
    color: red;
    font-size: 15px;
    margin-top: 2px;
  }

  .submit{

    background-color: #1D3557;
  }

  .cancel{
    background-color: #FF4040;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Userlogin/usermodal.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,WAAW;IACX,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,4BAA4B;IAC5B,+BAA+B;EACjC;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,cAAc;IACd,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;EACpB;;;;EAIA;IACE,UAAU;IACV,eAAe;IACf,eAAe;EACjB;;EAEA;;IAEE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');\n\n.modals {\n    position: absolute;\n    top: 50px;\n    left: 0px;\n    width: 100%;\n    height: 66%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 10;\n    font-family: 'Poppins';\n    font-weight: 400;\n  }\n  \n  .modal-content {\n    background-color: white;\n    padding: 20px;\n    border-radius: 8px;\n    width: 300px;\n    padding-top: 64px !important;\n    padding-bottom: 64px !important;\n  }\n  \n  .modal-content input {\n    width: 100%;\n    padding: 8px;\n    margin: 10px 0;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .modal-content button {\n    padding: 8px 16px;\n    margin: 5px;\n    border: none;\n    color: white;\n    cursor: pointer;\n    border-radius: 4px;\n  }\n  \n  \n  \n  .error {\n    color: red;\n    font-size: 15px;\n    margin-top: 2px;\n  }\n\n  .submit{\n\n    background-color: #1D3557;\n  }\n\n  .cancel{\n    background-color: #FF4040;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
