import React, { useState, useEffect } from "react";
import "./TrackAppointment.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import { useServiceContext } from "../contextapi/Servicecontext.js";
import ConfirmationModal from "./Modeles";
import apiservice from "../../apiServices.js";

const TrackAppointment = () => {
    const { refNo } = useParams();
    const navigate = useNavigate();
    const { translations } = useServiceContext();
    const [feedback, setFeedback] = useState([]);
    const [isLoadingFeedback, setIsLoadingFeedback] = useState(true);
    const [loading, setLoading] = useState(false); // Loader state
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    useEffect(() => {
        if (refNo) {
            fetchFeedback();
        }
    }, [refNo]);

    const fetchFeedback = async () => {
        setLoading(true); // Start loading
        const api = process.env.REACT_APP_API_URL + "trackservice";
        const customer_id = localStorage.getItem("userId");
        try {
               
            const data = await apiservice(api, 'POST', {
                reffrence_no: refNo,
                customer_id: customer_id,
            });

            if (data.status && data.result) {
                const fetchData = {
                    BussinessName: data.result.listing_title,
                    refNo: data.result.srt_reffrence_no,
                    token_no: data.result.token_no,
                    BussinessLogo: data.result.profile_pic,
                    services: data.result.services,
                    FirstName: data.result.FirstName,
                    LastName: data.result.LastName,
                    MiddleName: data.result.MiddleName,
                    date: data.result.date,
                    start: data.result.start,
                    persons: data.result.persons,
                    mobile: data.result.mobile,
                    Note: data.result.Note,
                    status: data.result.status,
                    reffrence_number: data.result.reffrence_number,
                    queue_status: data.result.queue_status,
                    email: data.result.email,
                    payment_mode: data.result.payment_mode,
                    durration:data.result.durration,
                    TotalAmount:data.result.TotalAmount,
                    logo_path: data.imagepath
                };
                setFeedback([fetchData]);
                
            } else {
                console.error("No valid data received or invalid data structure"); // Debugging line
                setFeedback([]); // Set feedback as an empty array if no data or invalid data
            }
        } catch (error) {
            console.error("Error fetching feedback:", error);
            setFeedback([]); // Set feedback as an empty array on error
        } finally {
            setIsLoadingFeedback(false);
            setLoading(false); // End loading
        }
    };



    const handleCancelBooking = async () => {
        setLoading(true); // Start loading
        const api = process.env.REACT_APP_API_URL + "canclebooking";
        try {
            const data = await apiservice(api, 'POST', {
                reffrence_no: feedback[0].reffrence_number,
                server_id: feedback[0].userId,
            });
            if (data.status) {
                setModalMessage("Booking canceled successfully");
                setShowModal(true);
                setFeedback([]);
            } else {
                setModalMessage("Failed to cancel booking");
                setShowModal(true);
            }
        } catch (error) {
            console.error("Error canceling booking:", error);
            setModalMessage("An error occurred while canceling the booking");
            setShowModal(true);
        } finally {
            setLoading(false); // End loading
        }
    };

    const handleOpenConfirmationModal = () => {
        setShowConfirmationModal(true);
    };

    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const handleConfirmCancelBooking = () => {
        setShowConfirmationModal(false);
        handleCancelBooking();
        navigate("/l/appointments", { replace: true }); 
    };

    const handleCloseModal = () => {
        setShowModal(false);
        navigate("/l/appointments", { replace: true });
    };

    if (isLoadingFeedback) {
        return <div><div><div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            height: '56px',
            width: '56px',
            animation: 'rotate 2s linear infinite',
            marginTop: '120px'
        }} className='loaders loader-overlay'>
        </div>
        <div className='loaders-overlay' style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '12px',
        }}>
            <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/whizzq-icon/whizzqlogo_dec23.png" width='120px' alt="Loading..." />
        </div></div></div>;
    }

    if (feedback.length === 0) {
        return <div>No appointment data found.</div>;
    }

    const handleInfo = (appo) => {
        navigate(`/l/info/${appo.refNo}`, { state: { appointment: appo } });
    };


    return (
        <>
            {feedback !== undefined && (
                <div className="track-container">
                    {loading ? (
                        <div><div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            height: '56px',
                            width: '56px',
                            animation: 'rotate 2s linear infinite',
                            marginTop: '120px'
                        }} className='loaders loader-overlay'>
                        </div>
                        <div className='loaders-overlay' style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '12px',
                        }}>
                            <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/whizzq-icon/whizzqlogo_dec23.png" width='120px' alt="Loading..." />
                        </div></div>
                    ) : (
                        <>
                            <div className="refToken">
                                <Link to="/l/appointments">
                                    <button className="back">
                                        <i className="fa-solid fa-arrow-left"></i>
                                    </button>
                                </Link>
                                {feedback.map((appo, index) => (
                                    <div key={index}>
                                        <div className="track-content">
                                            <b>
                                                <span>Ref/No: {appo.refNo}</span>
                                            </b>
                                            {appo.token_no && (
                                                <b>
                                                    <span style={{ color: "green", fontWeight: "bold" }}>
                                                        T/No: {appo.token_no}
                                                    </span>
                                                </b>
                                            )}
                                        </div>
                                        <div className="bussiness-details">
                                            <div className="bussiness-logo">
                                                {/* <img src={appo.profile_pic} alt="Business Logo" /> */}
                                                <img
                                src={
                                    appo.BussinessLogo && appo.BussinessLogo.trim() !== ''
                                        ? `${appo.logo_path}${appo.BussinessLogo}`
                                        : 'https://whizzdatalocal.s3-us-west-2.amazonaws.com/business_images/business_default.png'
                                }
                                alt="Business Logo"
                            />
                                            </div>
                                            <div className="bussiness-info">
                                                <b>
                                                    <span>{appo.BussinessName}</span>
                                                </b>
                                                <div className="servicee-info">
                                                    {appo?.services?.map(service => (
                                                        <div key={service.s_id} className="servicee-name">{service.s_name}</div>
                                                    ))}
                                                </div>
                                            </div>
                                            <button
                                                className="button-right"
                                                onClick={() => handleInfo(appo)}
                                            >
                                                <i className="fa-solid fa-circle-info"></i>
                                            </button>
                                        </div>
                                        <div className="customer-details">
                                            <span className="label">{translations.Name}:</span>
                                            <span className="value">{`${appo.FirstName} ${appo.LastName}`}</span>
                                            <span className="label">{translations.Date}:</span>
                                            <span className="value">{appo.date}</span>
                                            <span className="label">
                                                {translations["Scheduled Time"]}:
                                            </span>
                                            <span className="value">{appo.start}</span>
                                            <span className="label">{translations.Persons}:</span>
                                            <span className="value">{appo.persons}</span>
                                            {appo.queue_status && (
                                                <>
                                                    <span className="label">Waiting Time:</span>
                                                    <span className="value" style={{ color: "red" }}>
                                                        {appo.queue_status}
                                                    </span>
                                                </>
                                            )}
                                            <span className="label">{translations.Mobile}:</span>
                                            <span className="value">{appo.mobile}</span>
                                            <span className="label">{translations.Note}:</span>
                                            <span className="value">{appo.Note}</span>
                                            <span className="label">
                                                {translations["Current Status"]}:
                                            </span>
                                            <span className="value">{appo.status}</span>
                                        </div>
                                        <div className="qrr-image">
                                            <span className="qr-text">
                                                {translations["Your Booking QR code is"]}
                                            </span>
                                            <div className="qrr-code">
                                                <QRCode value={appo.reffrence_number} size={128} />
                                            </div>
                                            <span className="greetings">
                                                {translations["Thank You !"]}
                                            </span>
                                        </div>
                                        <div className="cancel-btn">
                                            <button
                                                className="cancel"
                                                onClick={handleOpenConfirmationModal}
                                            >
                                                {translations["Cancel Booking"]}
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    <ConfirmationModal
                        show={showModal}
                        message={modalMessage}
                        onClose={handleCloseModal}
                    />
                    <ConfirmationModal
                        show={showConfirmationModal}
                        onConfirm={handleConfirmCancelBooking}
                        onCancel={handleCloseConfirmationModal}
                    />
                </div>
            )}
        </>
    );
};

export default TrackAppointment;
