import React, { useEffect, useState } from 'react';
import './gallery.css';
import { useServiceContext } from '../contextapi/Servicecontext';
import apiservice from '../../apiServices';

function PhotoGallery() {
  const [photos, setPhotos] = useState([]);
  const [visiblePhotos, setVisiblePhotos] = useState([]);
  const [visibleCount, setVisibleCount] = useState(4); // Track the number of visible photos
  const [loadMore, setLoadMore] = useState(true);
  // const { locationid } = useServiceContext();

   const locationid = sessionStorage.getItem('businesslocationId')

  useEffect(() => {
    const api = process.env.REACT_APP_API_URL + 'getAllListingPhotosByListing';

    apiservice(api, 'POST', { listingId: locationid })
      .then(data => {
        if (data && data.length > 0) {
          setPhotos(data);
          setVisiblePhotos(data.slice(0, visibleCount)); // Display photos based on visibleCount
          setLoadMore(data.length > visibleCount); // Enable load more button if there are more photos
        } else {
          setPhotos([]);
          setVisiblePhotos([]);
          setLoadMore(false);
        }
      })
      .catch(error => {
        console.error('Error fetching or parsing data:', error);
      });
  }, [visibleCount, locationid]); // Include visibleCount in the dependency array

  const handleLoadMore = () => {
    // Increase the visible count by 4
    setVisibleCount(prevCount => prevCount + 4);
  };

  return (
    <div className="photo-gallery">
      {visiblePhotos.map(photo => (
        <img key={photo.photo_id} src={photo.file_path} alt={photo.file_name} />
      ))}
      {loadMore && visiblePhotos?.length > 0 && ( <button className="load-more-button" onClick={handleLoadMore}>Load more...</button>)}
      {!loadMore && photos.length === 0 && <div className='no-photo'>No Photos Available.</div>}
    </div>
  );
}

export default PhotoGallery;
