import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './userprofile.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useServiceContext } from '../contextapi/Servicecontext'
import apiservice from '../../apiServices';

export default function UserProfile() {
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');

  const {userImage , setUserImage} = useServiceContext()

  const [errors, setErrors] = useState({});
  var user = localStorage.getItem('userId');
  const today = new Date().toISOString().split('T')[0];

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUserImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Name is required';
    if (!mobileNumber) newErrors.mobileNumber = 'Mobile Number is required';
    else if (!/^\d{10}$/.test(mobileNumber)) newErrors.mobileNumber = 'Mobile Number must be 10 digits';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {

      const userProfile = {
        name: name,
        email: email,
        dob: dob,
        mobile: mobileNumber,
        profile_pic: userImage,
        server_id:  user
      };

      try {

        const api =  process.env.REACT_APP_API_URL  + 'saveProfile';
        const data = await apiservice(api, 'POST', userProfile);

          toast.success("Profile updated successfully!");
          localStorage.setItem('userProfileImage',data.data.profile_pic)
          setUserImage(data.data.profile_pic)


       
      } catch (error) {
        console.error(error);
        toast.error("An error occurred while updating profile.");
      }
    }
  };

  const fetchUserProfile = async () => {
    const userId = {
      server_id: user
    };
        
    try {

      const api =   process.env.REACT_APP_API_URL  + 'getProfile';
      const data = await apiservice(api, 'POST', { server_id:user });
        setName(data.data.name || '');
        setEmail(data.data.email || '');
        setMobileNumber(data.data.mobile || '');
        setDob(data.data.dob || '');
        setUserImage(data.data.profile_pic)

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);


  return (
    <div className="container mt-3 d-flex justify-content-center">
      <div className="card p-4 mb-4" style={{ maxWidth: '400px', width: '100%' }}>
        <div className="d-flex justify-content-center mb-4 position-relative">
          <div className="position-relative">
            <img
              src={userImage}
              alt="User"
              className="rounded-circle"
              width="100"
              height="100"
              style={{ cursor: 'pointer' }}
              onClick={() => document.getElementById('imageInput').click()}
            />
            <input
              type="file"
              id="imageInput"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
            <div
              className="position-absolute bg-primary text-white rounded-circle d-flex justify-content-center align-items-center"
              style={{
                width: '30px',
                height: '30px',
                right: '34px',
                bottom: '-9px',
                cursor: 'pointer'
              }}
              onClick={() => document.getElementById('imageInput').click()}
            >
              <i className="fa fa-plus"></i>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="name" className="user-details">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <small className="text-danger">{errors.name}</small>}
          </div>
          <div className="form-group mb-3">
            <label htmlFor="email" className="user-details">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <small className="text-danger">{errors.email}</small>}
          </div>
          <div className="form-group mb-3">
            <label htmlFor="dob" className="user-details">Date of Birth</label>
            <input
              type="date"
              className="form-control"
              id="dob"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              max={today}
            />
            {errors.dob && <small className="text-danger">{errors.dob}</small>}
          </div>
          <div className="form-group mb-4">
            <label htmlFor="mobileNumber" className="user-details">Mobile Number</label>
            <input
              type="text"
              className="form-control"
              id="mobileNumber"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
            {errors.mobileNumber && <small className="text-danger">{errors.mobileNumber}</small>}
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn user-update-btn">
              Update Your Profile
            </button>
            
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
