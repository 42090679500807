import React, { useEffect, useState } from 'react';
import './Businessbanner.css'; // Custom styles for the banner
import apiservice from '../../apiServices';
import { useServiceContext } from '../contextapi/Servicecontext';

export default function BusinessBanner({ apiEndpoint }) {
  const [bannerData, setBannerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0); // Track the active slide index

  const { businessOfferId, setBusinessOfferId, setActiveTab } = useServiceContext();
  const [businessInstruction, setBusinessInstruction] = useState([]);
  
// Retrieve the value from sessionStorage
const businessLocationId = sessionStorage.getItem('businesslocationId');

useEffect(() => {
  // Call API only if businessLocationId is undefined or null
    const fetchBannerData = async () => {
      const payload = {
        listing_id: businessLocationId, 
      };

      try {
        const response = await apiservice(apiEndpoint, 'POST', payload);
        const banners = response.bannerOfferList || [];
        // If listingCoverImage exists, add it as the first item in the banners array
        if (response.ListingCoverImage) {
          banners.unshift({
            file_path_web: response.ListingCoverImage,
            alt: 'Business Cover Image', // Optional description
            offer_id: null, // No offer ID for the cover image
          });
        }
        // Add businessInstruction images as banner items
      if (response.business_instruction) {
        response.business_instruction.forEach((instructionUrl, index) => {
          banners.push({
            file_path_web: instructionUrl,
            alt: `Instruction Image ${index + 1}`,
            offer_id: null, // No offer ID for instructions
          });
        });
      }
        setBannerData(response.bannerOfferList); // Assuming response contains the banner data
        setBusinessInstruction(response.business_instruction || []);
        // setListingCoverImage(response.ListingCoverImage); 
        // if (response.bannerOfferList.length > 0) {
        //   setBusinessOfferId(response.bannerOfferList[0].offer_id); // Set the offerId of the first banner initially
        // }
      } catch (err) {
        setError('An error occurred while fetching the banner data.');
      } finally {
        setLoading(false);
      }
    };

    // Add a 1-second delay before fetching the banner data
    const timeoutId = setTimeout(() => {
      fetchBannerData();
    }, 1000);

    // Cleanup function to clear timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  
}, [businessLocationId]);



  // Update the active slide index and set offerId accordingly
  const handleBannerClick = (offerId) => {
    if(offerId){

      // setBusinessOfferId(offerId); // Update the offerId in the context state
      setActiveTab('offer');
    }
  };

  const handlePrevClick = () => {
    const newIndex = (activeIndex === 0) ? bannerData.length - 1 : activeIndex - 1;
    setActiveIndex(newIndex);
    setBusinessOfferId(bannerData[newIndex].offer_id);
  };

  const handleNextClick = () => {
    const newIndex = (activeIndex === bannerData.length - 1) ? 0 : activeIndex + 1;
    setActiveIndex(newIndex);
    setBusinessOfferId(bannerData[newIndex].offer_id);
  };

  if (loading) return <div></div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="business-banner">
      <div className="banner-container">
        {bannerData?.length > 0 ? (
          <div id="businessBannerCarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
              {bannerData.map((_, index) => (
                <button
                  type="button"
                  key={index}
                  data-bs-target="#businessBannerCarousel"
                  data-bs-slide-to={index}
                  className={index === activeIndex ? 'active' : ''}
                  aria-current={index === activeIndex ? 'true' : ''}
                  aria-label={`Slide ${index + 1}`}
                />
              ))}
            </div>
            <div className="carousel-inner">
              {bannerData.map((banner, index) => (
                <div
                  key={index}
                  className={`carousel-item banner-slide-item ${index === activeIndex ? 'active' : ''}`}
                  onClick={() => handleBannerClick(banner.offer_id)}
                >
                  <img
                    src={banner.file_path_web || 'default-banner.jpg'}
                    className="d-block w-100"
                    alt={banner.alt || `Banner ${index + 1}`}
                  />
                
                </div>
              ))}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#businessBannerCarousel"
              data-bs-slide="prev"
              onClick={handlePrevClick}
            >
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#businessBannerCarousel"
              data-bs-slide="next"
              onClick={handleNextClick}
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        ) : (
         <></>
        )}
      </div>
    </div>
  );
}
