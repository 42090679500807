import React from 'react';
import { useState } from 'react';
import Billingprice from '../Billingprice/Billingprice';
import Staff from '../Staff/Staff';
import { useServiceContext } from '../contextapi/Servicecontext';
import Selectslot from '../Selectslot/Selectslot';
import { Link , useLocation } from 'react-router-dom';
import LoginModal from '../BookAppointment/Loginmodal';
import '../Billingprice/billingprice.css';
export default function Bothcomponent2() {

  const { showstaff ,bookingbutton } = useServiceContext();
  const {businessProfile , bookingdata, selectedServices,moduleType ,translations} = useServiceContext();
  const [isLoginModalVisible, setLoginModalVisibility] = useState(false);


  let location = useLocation();
  let selectslot = location.pathname.split("/");

  selectslot.pop();
  let slot =  selectslot.join("/")

  
  const uniqueServiceIndexSet = new Set();
  selectedServices.forEach(service => {

    if (service.serviceIndex !== undefined) {
      uniqueServiceIndexSet.add(service.serviceIndex);
    }
  });
  
  const serviceIndexArray = selectedServices
    .filter(service => service.serviceIndex !== undefined)
    .map(service => service.serviceIndex);
  
  const totalservice = serviceIndexArray.length;
  const staffIds = selectedServices.map(item => item.staffId)
  const uniqueStaffIds = [...new Set(staffIds)];
  const numberOfStaff = uniqueStaffIds.length;
  const isNextButtonEnable = numberOfStaff> 1;
  const buttonStyle = {

    backgroundColor: isNextButtonEnable ? '#1D3557' : '#4A5D78'
  };
  const buttonStyles = {
    backgroundColor: bookingbutton?   'lightcoral' :  '#E63946'
  };


  const handleBookAppointment = () => {
    setLoginModalVisibility(true)
  }

  return (
      <>
                <div style={{ display: isLoginModalVisible ? 'block' : 'none' }}></div>
      <div className='col-md-8 mt-3'>
      <div className="d-sm-block d-md-none mb-3">
      <div className='card'>
                  <div className='card-body'>
                    <div className='d-flex align-items-center'>
                      <div>
                      <img
    src={businessProfile.locationImage ? businessProfile.locationImage : 'https://whizzdatalocal.s3-us-west-2.amazonaws.com/business_images/business_default.png'}
    alt="Your Image"
    className='bill-branch-img'
  />
                      </div>
                      <div>
                          <text className=' billing-head'>{businessProfile.locationName} </text>
                          <text className='billing-subhead'>{} </text>
                      </div>
                  </div>
                  </div>
                </div>
            </div>
          <div className='card'>
            <div className='card-body'>
            {showstaff ? <Staff /> : <Selectslot />}
            </div>
          </div>
        </div>
        <div className='col-md-4 mt-3'>
        <div className='card d-none d-md-block  '>
            <div className='card-body  '>
            <Billingprice />
            </div>
          </div>
        </div>
           {showstaff ?
         
      ( 
         <div className="tab-fixed d-sm-block d-md-none">
                <div className='d-flex justify-content-between p-2'>
                  <div className='col-md-6'>
                      <p className='num-service'><span className='fw-bold'>{totalservice}</span>  {translations.serviceadded}</p>
                  </div>
                  <div className='col-md-6 '>
                    <div className='d-flex align-items-center'>
                      <span className='num-service-price'><i className="fa-solid fa-indian-rupee-sign"></i> {bookingdata.totalCost}</span>
                   <Link to={`${slot}/timeslot` }>  <button type="button" className="btn-sm-nxt float-end" style={buttonStyle} disabled={!isNextButtonEnable} >{translations.next}</button> </Link>
    
                    </div>

                  </div>
                </div>
          </div> ) : ( 
            <div className="tab-fixed d-sm-block d-md-none">
                <div className='d-flex justify-content-between p-2'>
                  <div className='col-md-6'>
                      <p className='num-service'><span className='fw-bold'>{totalservice}</span> {translations.serviceadded}</p>
                  </div>
                  <div className='col-md-6 '>
                    <div className='d-flex align-items-center'>
                    <span className='num-service-price'><i className="fa-solid fa-indian-rupee-sign"></i> {bookingdata.totalCost}</span>
                    {moduleType !== "token" && (
                  <button type="button" className="btn-sm-nxt float-end" onClick={() => handleBookAppointment()} disabled={bookingbutton} style={buttonStyles}>{translations.book}</button>
                )}
                    </div>

                  
                </div>
          </div>   
        <div>
          {isLoginModalVisible && (
        <LoginModal onClose={() => setLoginModalVisibility(false)}/> 
      )}

        </div>
          </div>
          )
}
        
        
      </>
    
  );
}
