import React, { useEffect, useState } from 'react';
import "./Ratings.css";
import apiservice from '../../apiServices';

const Ratings = ({ show, onClose, reffrence_number, appointment }) => {
    const [rating, setRating] = useState(1);
    const [comment, setComment] = useState('');
    const [message, setMessage] = useState('');
    const [ratingGiven, setRatingGiven] = useState(false);
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');

    const userId = localStorage.getItem('userId');

    useEffect(() => {
        if (show) {
            setRating(1);
            setComment('');
            setMessage('');
            setRatingGiven(false);
            setLoading(true);
            setSuccessMessage('');

            const fetchData = async () => {

                const api = process.env.REACT_APP_API_URL + 'checkFeedBackAllowForReference';

                const payloads = {
                    user_id: userId,
                    appointment_reference: reffrence_number
                };

                try {

                    const data = await apiservice(api, 'POST', payloads);


                    if (data.status === false) {
                        setMessage("You have already given a rating for this appointment.");
                        setRatingGiven(true);
                    } else if (data) {
                        setRating(data.rating || 1);
                        setComment(data.comment || '');
                    }
                } catch (error) {
                    console.error('Error fetching rating details:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [show, reffrence_number, userId]);

    if (!show) return null;

    const handleRating = (value) => {
        setRating(value);
    };

    const onCloseModal = () => {
        window.location.reload(); 
    };

    const handleSubmit = async () => {
        if (ratingGiven) return;

        const api = process.env.REACT_APP_API_URL + 'saveRatingForGivenReferenceNumber';


        const payload = {
            user_id: userId,
            appointment_reference: reffrence_number,
            rating,
            comment,
        };

        try {

            const data = await apiservice(api, 'POST', payload);


            setSuccessMessage('Ratings saved successfully'); // Set success message

            setTimeout(() => {
                setSuccessMessage('');
                onCloseModal(); // Close the modal and refresh the `pa`ge
            }, 5000);
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Rate the Service</h2>
                {loading ? (
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            height: '56px',
                            width: '56px',
                            animation: 'rotate 2s linear infinite',
                            marginTop: '120px'
                        }} className='loaders loader-overlay'>
                        </div>
                        <div className='loaders-overlay' style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '12px',
                        }}>
                            <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/whizzq-icon/whizzqlogo_dec23.png" width='120px' alt="Loading..." />
                        </div>
                    </div>
                ) : (
                    <>
                        {message && <p className="message">{message}</p>}

                        {successMessage ? (
                            <>
                                <p className="success-message">{successMessage}</p>
                            </>
                        ) : (
                            !ratingGiven && (
                                <>
                                    <div className="rating-stars">
                                        {[1, 2, 3, 4, 5].map((star) => (
                                            <span
                                                key={star}
                                                className={`star ${star <= rating ? 'filled' : ''}`}
                                                onClick={() => handleRating(star)}
                                            >
                                                ★
                                            </span>
                                        ))}
                                    </div>
                                    <div className="comments">
                                        <textarea
                                            placeholder="Leave a comment"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        ></textarea>
                                    </div>
                                </>
                            )
                        )}
                    </>
                )}
                {appointment.googleReview_link && appointment.status == 'served' && (
                    <p>
                     <div> </div>

                        <div> <div className="googlerating">Review business on Google ! </div>
                           
                           <div><a href={appointment.googleReview_link} target="_blank" rel="noopener noreferrer">
                                <img src='https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/images/images.png' alt='ggl-btn' width='200px' height='90px'></img>
                                </a>
                                </div> 
                                </div>


                    </p>
                )}


                <div className="modal-buttons">
                    {!ratingGiven && !loading && !successMessage && (
                        <button onClick={handleSubmit} className="submit">Submit</button>
                    )}
                    <button onClick={onCloseModal} className="close">Close</button>
                </div>
            </div>
        </div>
    );
};

export default Ratings;
