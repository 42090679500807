import React, { useState } from 'react';
import { useServiceContext } from '../contextapi/Servicecontext';
import './about.css';

const About = () => {
    const { description, url } = useServiceContext();
    const isDescriptionEmpty = !description || description === 'No Info Available';

    return (
        <div className='about-container'>
            {isDescriptionEmpty && !url && (
                <p className='info'>
                    No Information Available.
                </p>
            )}
            {!isDescriptionEmpty && (
                <p className='info'>
                    {description}
                </p>
            )}
            {url && (
                <label>
                    Website Link: <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                </label>
            )}
        </div>
    );
};

export default About;
