import React, { useEffect, useState } from 'react';
import './gallery.css';
import { useServiceContext } from '../contextapi/Servicecontext';
import apiservice from '../../apiServices';

function PhotoGallery() {
  const [photos, setPhotos] = useState([]);
  const [visiblePhotos, setVisiblePhotos] = useState([]);
  const [visibleCount, setVisibleCount] = useState(4); // Track the number of visible photos
  const [loadMore, setLoadMore] = useState(true);
  const [isLoading,setIsLoading] = useState(false)
  // const { locationid } = useServiceContext();

   const locationid = sessionStorage.getItem('businesslocationId')

  useEffect(() => {
    setIsLoading(true)
    const api = process.env.REACT_APP_API_URL + 'getAllListingPhotosByListing';

    apiservice(api, 'POST', { listingId: locationid })
      .then(data => {
        if (data && data.length > 0) {
          setPhotos(data);
          setVisiblePhotos(data.slice(0, visibleCount)); // Display photos based on visibleCount
          setLoadMore(data.length > visibleCount); // Enable load more button if there are more photos
        } else {
          setPhotos([]);
          setVisiblePhotos([]);
          setLoadMore(false);
        }
      })
      .catch(error => {
        console.error('Error fetching or parsing data:', error);
      })
      .finally(() => {
        setIsLoading(false); // Stop loading after data fetch
      });
  }, [visibleCount, locationid]); // Include visibleCount in the dependency array

  const handleLoadMore = () => {
    // Increase the visible count by 4
    setVisibleCount(prevCount => prevCount + 4);
  };

  return (
    <div>
      {isLoading ? (
        <div>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            height: '56px',
            width: '56px',
            animation: 'rotate 2s linear infinite',
            marginTop: '80px'
        }} className='loaders loader-overlay'>
        </div>
        <div className='loaders-overlay' style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '12px',
        }}>
            <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/whizzq-icon/whizzqlogo_dec23.png" width='120px' alt="Loading..." />
        </div>
    </div>
      ) : (
    <div className="photo-gallery">
  <>
      {visiblePhotos.map(photo => (
        <img key={photo.photo_id} src={photo.file_path} alt={photo.file_name} className='gallery-image' />
      ))}
      {loadMore && visiblePhotos?.length > 0 && ( <button className="load-more-button" onClick={handleLoadMore}>Load more...</button>)}
      {!loadMore && photos.length === 0 && <div className='no-photo'>No Photos Available.</div>}
      </>
  
      </div>
      )}
      </div>
    
  );
}

export default PhotoGallery;
