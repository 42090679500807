import React from 'react';
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import { RAZORPAY_OPTIONS } from '../../razorpayConfig';
import './payment.css'

const PaymentComponent = () => {
  // const Razorpay = useRazorpay();
  const { error, isLoading, Razorpay } = useRazorpay();
   
  const razorpaykey = process.env.REACT_APP_RAZORPAY_KEY_ID

  const handlePayment = () => {
    const options = {
      key: razorpaykey,
      amount: 50000, // Amount in paise
      currency: "INR",
      name: "Test Company",
      description: "Test Transaction",
      order_id: "order_PL4eIRTfseeIPe", // Generate order_id on server
      handler: (response) => {
        alert("Payment Successful!");
      },
      prefill: {
        name: "John Doe",
        email: "john.doe@example.com",
        contact: "9999999999",
      },
      theme: {
        color: "#F37254",
      },
    };

    const razorpayInstance = new Razorpay(options);
    razorpayInstance.open();
  };


  return (
    <div className='payment-btn'>
      <button onClick={handlePayment}>Pay Now</button>
    </div>
  );
};

export default PaymentComponent;

