// LoginModal.js
import React, { useState, useEffect } from "react";
import "./userlogin.css";
import BasicOTPComponent from "../BookAppointment/BasicOTPComponent";
import { useServiceContext } from '../contextapi/Servicecontext';
import Cookies from "js-cookie";
import ReactGA from 'react-ga4';
import GoogleLoginButton from "./GoogleLogin";
import AppleSignInButton from "./AppleLogin";
import apiservice from "../../apiServices";
import AppleLogin from "./AppleLogin";

const UserLogin = ({ onClose, setLoginModalVisibility }) => {


    const [loading, setLoading] = useState(false)
    const [otploader, setOtploader] = useState(false)
    const [onbutton, setOnbutton] = useState(false)
    const [clicked, setClicked] = useState('Login');
    const [showmessage, setShowmessage] = useState();
    const [usermessage, setuserMessage] = useState();

    const { bookingdata, setBookingdata,abled,selectedServices,
        translations, lastName, setLastName} = useServiceContext();


    const [firstName, setFirstName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [isMobileValid, setIsMobileValid] = useState(true);
    const [userdetails, setUserdetails] = useState([]);
    const [bookingSuccess, setBookingSuccess] = useState(false);
    const [isMobileError, setIsMobileError] = useState('');
    const [isFirstNameError, setIsFirstNameError] = useState('');
    const [isLastNameError, setIsLastNameError] = useState('');
    const [invalidotp, setInvalidotp] = useState([])
    const [qrimage, setQrimage] = useState();
    const [message, setMessage] = useState();
    const [reference, setReference] = useState();
    const [address, setAddress] = useState("")
    const [IsAddressError, setIsAddressError] = useState();
    const [bookingfor, setBookingfor] = useState('self');
    const [signUp, setSignUp] = useState(false);
    const [sendType, setSendType] = useState('login');

    let anyRequiresAddress = false;
    const bookingToken = Cookies.get('bookingToken')
    for (const service of selectedServices) {
        if (service.address_required === 1) {
            anyRequiresAddress = true;
            break;
        }
    }

    const handleable = (abled) => {
        setOnbutton(abled >= 3);
    };

    const isValidIndianMobileNumber = (number) => {
    const mobileRegex = /^[6-9]\d{9}$/;
     return mobileRegex.test(number);
    };

    const isvalidname = (Name) => {
    const nameRegex = /^[A-Za-z]{2,}$/;
     return nameRegex.test(Name);
    }

    const isValidAddress = (Address) => {
        const usAddressRegex = /^[a-zA-Z0-9\s,/&-]+$/

        if (anyRequiresAddress) {

            return usAddressRegex.test(Address);
        }

        return true
    }
    const handlesign = () => {

        setClicked('Signup');
        setSendType('register')
        setSignUp(true)
    }

    const handlelogin = () => {

        setSendType('login')
        setSignUp(false)
        setClicked('Login');
    }

    const handleSendOtp = async () => {

        if (isValidIndianMobileNumber(mobileNumber.trim())) {
            try {
                const payload = {
                    mobileNumber,
                    firstName,
                    lastName,
                    sendType
                };

                setOtploader(true)
                const api = process.env.REACT_APP_API_URL + 'LoginOrSignUpForCustomer';
                const data = await apiservice(api, 'POST', payload);
                setUserdetails(data);
                localStorage.setItem('userId', JSON.stringify(data.userId))
                localStorage.setItem('mobileNumber', JSON.stringify(data.mobileNumber))

                if (data.status == 'true') {

                    setOtpSent(true);
                    setIsMobileValid(true);
                }

                else if (data.status == 'false') {
                    setShowmessage(true)
                    setuserMessage(data.message)
                }
                else {

                    setIsMobileValid(false);
                }
                setOtploader(false)
            }
            catch (error) {
                console.error('Error calling sendOTPConsumer API:', error);
            }

        }
        else {

            setIsMobileError(isValidIndianMobileNumber(mobileNumber.trim()) ? '' : 'Enter correct mobile number');
            setIsFirstNameError(isvalidname(firstName.trim()) ? '' : 'Enter valid first name');
            setIsLastNameError(isvalidname(lastName.trim()) ? '' : 'Enter valid last name');
            setIsAddressError(isValidAddress(address.trim()) ? '' : 'Enter Valid Address')

        }
    };

    const confirmbooking = async () => {

        const api = process.env.REACT_APP_API_URL+ 'verifyOtpForCustomer';
        const userId = JSON.parse(localStorage.getItem('userId'))
        const mobilenumber = JSON.parse(localStorage.getItem('mobileNumber'))

        try {
            setLoading(true)

            const responseData = await apiservice(api, 'POST', {
                firstName: firstName,
                lastName: lastName,
                userId: userId,
                mobileNumber: mobilenumber,
                otp: bookingdata.otp || '',
              });
                ReactGA.event({
                    category: 'User',
                    action: 'Clicked a button'
                })
                setQrimage(responseData.qrcodeImage);
                setMessage(responseData.message);
                setReference(responseData.srt_reffrence_no);

                if (responseData.status === 'false') {
                    setInvalidotp(responseData.message)
                }

                if (responseData.status === 'true') {

                    const username = responseData.profile;
                    const nameParts = username.split(' ');
                    const firstName = nameParts[0];
                    const lastName = nameParts.slice(1).join(' ');
                    setBookingSuccess(true);
                    Cookies.set('bookingToken', responseData.token, { expires: 30 }); // Set expiry date as required
                    Cookies.set('firstname', firstName, { expires: 30 }); // Set expiry date as required
                    Cookies.set('lastname', lastName, { expires: 30 });
                    Cookies.set('mobile', responseData.mobile, { expires: 30 })
                    localStorage.set('userProfileImage', responseData.profile_pic , {expires: 30})
                    setBookingdata((prevBookingdata) => ({
                        ...prevBookingdata,
                        message: responseData.message,
                        referenceNumber: responseData.srt_reffrence_no,
                        qrcodeImage: responseData.qrcodeImage
                    }));

                } else {
                    console.warn('No staff is working for the selected service');
                }
            setLoading(false)
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    }

    useEffect(() => {
        handleable(abled);
    }, [abled]);


    useEffect(() => {
        const firstNameCookie = Cookies.get('firstname');
        const lastNameCookie = Cookies.get('lastname');
        const mobileCookie = Cookies.get('mobile')
        if (firstNameCookie && lastNameCookie) {

            setFirstName(firstNameCookie);
            setLastName(lastNameCookie);
            setMobileNumber(mobileCookie);


        }
    }, []);

    const renderContent = () => {
        const ButtonStyle = {
            margin: "40px 1px 12px", backgroundColor: ' #1D3557', width: '100%',
            padding: '11px'
        }

        const ButtonStyleotp = {
            width: '100%',
            backgroundColor: '#1D3557'

        };

        if (bookingSuccess) {


            return (

                onClose()
                
            );
        } else if (otpSent) {
            return (
                <div>
                    <div className="modal-title">
                        <span>{translations.step} 4/4 </span>
                        <span style={{ paddingLeft: "74px" }}>{translations.verify}</span>
                        <div style={{ marginLeft: '130px' }}>
                            <span onClick={onClose}><i className="fas fa-xmark"></i></span>


                        </div>
                    </div>


                    <br />
                    <BasicOTPComponent />

                    <div className="otp-text" >
                        <div>  <span style={{ color: '#7F8487' }}>{translations.didntrecievedotp}</span>
                            <span className="resend" style={{ float: 'right', color: '#FF0000' }} onClick={handleSendOtp}  >        {otploader ? 'Sending OTP...' : translations.resend}
                            </span>
                        </div>

                        <div style={{ color: 'black', textAlign: 'center', color: "red", marginTop: '20px' }}>{invalidotp}</div>

                    </div>
                    {

                        loading ?
                            (

                                loading &&
                                <button className="btn btn-primary" type="button" disabled style={ButtonStyleotp}>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
                                    Loading...
                                </button>


                            ) : (

                                <button
                                    onClick={confirmbooking}
                                    disabled={!onbutton}
                                    type="button"
                                    className="btn btn-primary send-otp "
                                    style={{ marginTop: '53px' }}
                                >
                                    {translations.login}
                                </button>
                            )

                    }
                </div>
            );
        } else {

            return (

                <div className="user-login">
                    <div className="modal-title">

                        <div className="userlogin d-flex align-items-center" style={{ paddingLeft: '90px' }}>
                            <button
                                onClick={handlelogin}
                                className={`me-2 ${clicked === 'Login' ? 'active' : ''}`}
                            >
                                Login
                            </button>
                            <span className="px-2">or</span>
                            <button
                                onClick={handlesign}
                                className={`${clicked === 'Signup' ? 'active' : ''}`}
                            >
                                Sign up
                            </button>
                        </div>

                        <div style={{ marginLeft: '65px', fontSize: '20px', position: 'fixed', right: '33px' }}>
                            <span onClick={onClose}><i className="fas fa-xmark"></i></span>

                        </div>
                    </div>
                    <br></br>
                    {signUp && <div>
                        <div>
                            <input
                                type="text"
                                className="form-control"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder={translations.firstname}
                                required
                                disabled={bookingfor == 'self' && bookingToken != undefined} // Disable if booking is for self


                            />
                            <div style={{ color: 'red' }}>{isFirstNameError}</div>

                        </div>
                        <div>
                            <input
                                type="text"
                                className='form-control'
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder={translations.lastname}
                                required
                                disabled={bookingfor == 'self' && bookingToken != undefined}

                            />
                        </div>       <div style={{ color: 'red' }}>{isLastNameError}</div>
                    </div>
                    }

                    <div>
                        <input
                            type="text"
                            className='form-control'
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            placeholder={translations.mobile}
                            required
                            disabled={bookingfor == 'self' && bookingToken != undefined}

                        />

                        <div style={{ color: 'red' }} >{isMobileError}</div>

                    </div>


                    <br />

                    {

                        otploader ? (
                            <button className="btn btn-primary" type="button" disabled style={ButtonStyleotp}>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
                                Loading...
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-primary send-otp"
                                onClick={handleSendOtp}
                            >
                                {translations.otp}
                            </button>
                        )
                    }
                     <div className="ggl-btn" style={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center' }}>
  <GoogleLoginButton 
    setLoginModalVisibility={setLoginModalVisibility} 
    style={{ width: '150px', height: '50px' }} // Set width and height for the Google login button
  />
  <AppleLogin 
    setLoginModalVisibility={setLoginModalVisibility} 
    style={{ width: '150px', height: '50px' }} // Set width and height for the Apple login button
  />
</div>


                        
                    {showmessage && <div className="user-msg">{usermessage}  </div>}
                </div>
            );
        }

    };

    return (
        <>

            <div className={`custom-modal ${otpSent ? "show" : ""}`}>
                <div className="modal-body">{renderContent()}</div>


            </div>
        </>


    );
};


export default UserLogin;
