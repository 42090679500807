
import React, { useState, useEffect } from "react";
import { Datepicker } from "@meinefinsternis/react-horizontal-date-picker";
import './selectslot.css';
import LoginModal from "../BookAppointment/Loginmodal";
import '../BookAppointment/loginmodal.css';
import enIN from 'date-fns/locale/en-IN'; // Import the locale for Indian Standard Time
import { useServiceContext } from "../contextapi/Servicecontext";
import { useNavigate } from "react-router-dom";
import apiservice from "../../apiServices";


function slotToTime(slot) {
  const Minut = 5; // Replace 15 with your desired value
  const totalMinutes = slot * Minut;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const formattedHours = (hours % 12 === 0 ? 12 : hours % 12).toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const startSlotTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

  return startSlotTime;
}
const Example = () => {

  const { selectedServices, setSelectedServices,locationName, bookingdata,moduleType, setBookingdata, bookingbutton, setBookingButton, language, changeLanguage, translations, totalservice , setTotalservice , offervalidDate ,setShowoffer} = useServiceContext();
  const [isLoginModalVisible, setLoginModalVisibility] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [chunkSize, setChunkSize] = useState(6); // Default chunk size
  const [personCount, setPersonCount] = useState(1);
  const flow = sessionStorage.getItem('flow'); // Assuming you have a 'flow' key in sessionStorage
  const [advancebookingflag, setAdvanceBookingFlag] = useState(true); // Added state for advance booking flag
  const [apiflag ,setApiflag] = useState(true);
  const [advanceMsg , setAdvancemsg]  = useState()
  const initialShowValue = flow == 1;
  const [show, setShow] = useState(initialShowValue)
  const [controller, setController] = useState(null)

  useEffect(() => {
    setShow(flow == 1);
  }, [flow]);

  const loc_id = sessionStorage.getItem('locationId')
  const location_id = {

    'location_id': loc_id
  };

  const incrementPerson = () => {
    setPersonCount(prevCount => prevCount + 1);
    setBookingdata((prevBookingData) => ({
      ...prevBookingData,
      personCount: personCount + 1,
    }));
  };

  const decrementPerson = () => {
    if (personCount > 1) {
      setPersonCount((prevCount) => prevCount - 1);

      setBookingdata((prevBookingData) => ({
        ...prevBookingData,
        personCount: personCount - 1,
      }));
    }
  };

  const handleSlotSelection = (time) => {
    if (selectedSlot == time) {
      setSelectedSlot(null);
    } else {
      setSelectedSlot(time);
    }
  };

  const [slots, setSlots] = useState();
  const selectedServicesArray = selectedServices.map(service => {
    return {
      location_id: location_id.location_id,
      staffId: service.staffId,
      date: service.date,
      serviceIndex: service.serviceIndex, // Make sure serviceIndex is part of your service object
    };

  });


  const mergedServicesObject = selectedServices.reduce((acc, service) => {
    if (service.location_id !== undefined) {
      acc.location_id = service.location_id.location_id;
    }

    if (service.staffId !== undefined) {
      acc.staffId = service.staffId;
    }

    if (service.date !== undefined) {
      acc.date = service.date;
    }

    return acc;
  }, {});


  const [date, setDate] = useState({

    startValue: new Date(),

  });

  const [selectedTimeCategory, setSelectedTimeCategory] = useState(null);
  const [filteredTimeSlots, setFilteredTimeSlots] = useState([]);
  const [shouldInitialize, setShouldInitialize] = useState(false);

  const handleChange = async (d) => {

    if (controller) {
      controller.abort();
    }
    
    const newController = new AbortController();
    setController(newController);


    const [startValue] = d;

    const currentDate = new Date();
currentDate.setHours(0, 0, 0, 0); // Remove time part for comparison

const selectedDate = new Date(startValue);
selectedDate.setHours(0, 0, 0, 0)
    // Update advancebookingflag based on conditions
    const newDate = new Date(selectedDate);

    const advanceFlag = apiflag!== false 
    ? apiflag 
    : newDate.getTime() === currentDate.getTime();
  
  setAdvanceBookingFlag(advanceFlag);
    setSelectedServices(prevServices => {
      const updatedServices = prevServices.filter(service => !service.date);
      setDate((prev) => ({ ...prev, startValue }));
      return [...updatedServices, { date: startValue }];

    });

    handleTimeCategoryChange(selectedTimeCategory || "morning");

    const indianTimeZone = 'Asia/Kolkata';
    const indianDate = new Date(startValue).toLocaleString('en-US', { timeZone: indianTimeZone });
    const formattedIndianDate = indianDate;
    const mergedAndValuesArray = {
      location_id: location_id.location_id,
      staffId: mergedServicesObject.staffId,
      date: formattedIndianDate,
    };
    const bookingData = {
      location_id: mergedAndValuesArray.location_id,
      staffId: mergedAndValuesArray.staffId,
      date: mergedAndValuesArray.date,
      serviceIndex: selectedServicesArray.map(service => service.serviceIndex)
        .filter(index => index !== undefined && index !== null).join(','),
      startTime: selectedSlot, // Include selected start time if available
      personCount: personCount,

    };

    setBookingdata(bookingData)

    try {

      const api = process.env.REACT_APP_API_URL + 'getSlots'  
      const data = await apiservice(api, 'POST', mergedAndValuesArray, { signal: newController.signal });
      localStorage.setItem('slots', JSON.stringify(data));
      setApiflag(data.advancebookingflag)
      setAdvancemsg(data.message)
      setSlots(data);
    }
    catch (error) {
      if (error.name === 'AbortError') {
      } else {
        console.error("Error fetching data:", error);
      }
    }
  };

  const setDefaultTimeCategory = () => {
    const currentHour = new Date().getHours();
    const isNextDay = (selectedDate) => {
      const currentDate = new Date();
      return selectedDate > currentDate;  
    };

    var defaultCategory ;

    if (isNextDay(date.startValue)) {
      defaultCategory = "morning";
    } else {
      if (currentHour >= 9 && currentHour < 12) {
        defaultCategory = "morning";
      } else if (currentHour >= 12 && currentHour < 17) {
        defaultCategory = "afternoon";
      } else if (currentHour >= 17 && currentHour < 20) {
        defaultCategory = "evening";
      } else if ((currentHour >= 20 && currentHour <= 23) || (currentHour >= 0 && currentHour < 6)) {
        defaultCategory = "night";
      }
    }
    handleTimeCategoryChange(defaultCategory);
  };

  useEffect(() => {
    setShouldInitialize(true);
  }, []);
  useEffect(() => {
    setSelectedSlot(null);
    handleChange([date.startValue]);

  }, [date.startValue]);

  useEffect(() => {
    setDefaultTimeCategory();
  }, [date.startValue, shouldInitialize ]);
  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setDate(minDate.getDate() + 7);

  const customStyles = {
    container: {
      width: "500px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    dateItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "7px",
      border: `1px solid #1D3557`,
      borderRadius: "15px",

    },
    dateItemText: {
      fontSize: "20px",
    },

    selectedDateItem: {
      background: "green", // Set the background color for the selected date
      color: "white", // Set the text color for the selected date
    },
  };

  const startdate = slots?.slots;
  const startdateArray = startdate ? startdate.map(item => item.start) : [];
  const formattedTimeArray = startdateArray.map(startdate => slotToTime(startdate));
  const sortDates = (dates) => {
    return dates.sort((a, b) => new Date(a) - new Date(b));
  };

  useEffect(() => {

    if (shouldInitialize && formattedTimeArray.length > 0) {  
      const sortedDates = sortDates(formattedTimeArray);
      setFilteredTimeSlots(sortedDates);
      setShouldInitialize(false);
    }

  }, [shouldInitialize, formattedTimeArray, selectedTimeCategory]);

  const handleTimeCategoryChange = (timeCategory) => {

    setSelectedTimeCategory(timeCategory);
    const filteredSlots = formattedTimeArray.filter(time => {
      const [hour, minute, ampm] = time.split(/:| /);
        let formattedHour = parseInt(hour, 10);
      if (ampm === 'PM' && formattedHour !== 12) {
          formattedHour += 12;
      } else if (ampm === 'AM' && formattedHour === 12) {
          formattedHour = 0; // Midnight case
      }
      switch (timeCategory) {
          case "morning":
              return formattedHour >= 9 && formattedHour < 12;
          case "afternoon":
              return formattedHour >= 12 && formattedHour < 16;
          case "evening":
              return formattedHour >= 16 && formattedHour < 19;
          case "night":
              return (formattedHour >= 19 && formattedHour <= 23) || (formattedHour >= 0 && formattedHour < 6);
          default:
              return true;
      }
  });
  
  setFilteredTimeSlots(filteredSlots);
  
  };

  function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }


    return result;


  }
  const numberOfTimeSlots = filteredTimeSlots.length;
  const isButtonDisabled = selectedSlot === null || numberOfTimeSlots === 0  
  setBookingButton(isButtonDisabled); 

  const buttonStyle = {

    backgroundColor: isButtonDisabled ? '#ffcccc' : '#E63946',
    cursor: isButtonDisabled ? 'not-allowed' : 'pointer', // Change cursor when disabled
  };


  useEffect(() => {

    const handleResize = () => {
      // Adjust chunkSize based on window width
      if (window.innerWidth < 750) {
        setChunkSize(3);
      } else {
        setChunkSize(6);
      }
    };

    // Initial adjustment
    handleResize();

    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  const navigate = useNavigate();



  useEffect(() => {
    handleTimeCategoryChange("morning")
  },[startdate])




  useEffect(() => {
    // If selectedSlot changes, update bookingdata
    setBookingdata(prevData => ({
      ...prevData,
      startTime: selectedSlot,  // Reflect the latest selectedSlot
    }));
  }, [selectedSlot]);


  const handleBookAppointment = () => {
    setLoginModalVisibility(true);
    
    // Ensure selectedServices and offervalidDate are defined
    if (!selectedServices || !offervalidDate) {
        setShowoffer(false);
        return; // Exit early if either value is undefined or null
    }

    const isValid = isOfferValidForSelectedService(selectedServices, offervalidDate);
    if (!isValid) {
        setShowoffer(false);
    }
}

function isOfferValidForSelectedService(selectedServices, offervalidDate) {
    // Ensure offervalidDate has the necessary properties
    if (!offervalidDate || !offervalidDate.offer_valid_from || !offervalidDate.offer_validity_at) {
        return false;
    }

    // Find the service object that contains the date
    const serviceWithDate = selectedServices.find(service => service.date);

    // If no service object with a date is found, return false
    if (!serviceWithDate) {
        return false;
    }
    const selectedDate = new Date(serviceWithDate.date).setHours(0, 0, 0, 0);
    const offerStartDate = new Date(offervalidDate.offer_valid_from).setHours(0, 0, 0, 0);
    const offerEndDate = new Date(offervalidDate.offer_validity_at).setHours(0, 0, 0, 0);
    return selectedDate >= offerStartDate && selectedDate <= offerEndDate;
}

  return (

    <>
    
      <div className="row">
        <div>
          <div className='text-center'>
            <span className='step float-start' onClick={() => navigate(-1)}><i class="fa-solid fa-arrow-left"></i>  </span> <span className='select'>{translations.select_date_time}</span>  <span className="step-right"> {translations.step} 3/4   </span>
          </div>
        </div>
      </div>
      <div className="custom-calender">

        <Datepicker

          onChange={handleChange}
          locale={enIN}
          minDate={minDate}
          maxDate={maxDate}
          customStyles={customStyles}
          selected={selectedSlot}
          startValue={date.startValue}
          endValue={date.startValue}

        />
      </div>
      {show ? (
  <div>
    <div className="person-number" style={{ textAlign: 'center', marginTop: '15px' }}>
      <button className="flw-btn-remove" onClick={decrementPerson}>
        <i className="fa-solid fa-minus text-white"></i>
      </button>
      <span className="btn-person" style={{ margin: '0 10px' }}>
        Number of person: <span>{personCount}</span>
      </span>
      <button className="flw-btn" onClick={incrementPerson}>
        <i className="fa-solid fa-plus text-white"></i>
      </button>
    </div>

    <div className="d-flex mt-4">
      {['morning', 'afternoon', 'evening', 'night'].map((timeCategory) => (
        <div
          key={timeCategory}
          className="col-md-3 custom-col text-center"
          onClick={() => handleTimeCategoryChange(timeCategory)}
          style={{
            cursor: "pointer",
            background: selectedTimeCategory === timeCategory ? "#1D3557" : "transparent",
            color: selectedTimeCategory === timeCategory ? "white" : "black",
            WebkitTextFillColor: selectedTimeCategory === timeCategory ? "white" : "#1D3557"
          }}
        >
          {translations[timeCategory]} <span className="time-category-icons">
            <i className={`fas ${timeCategory === 'morning' ? 'fa-cloud-sun' : timeCategory === 'afternoon' ? 'fa-sun' : timeCategory === 'evening' ? 'fa-cloud-moon' : 'fa-moon'} my-icon`}></i>
          </span>
        </div>
      ))}
    </div>

    <div className="time-slot-container mt-3">

  {filteredTimeSlots.length > 0 ? (
    <div className="d-flex flex-wrap justify-content-center mt-2">
      {filteredTimeSlots.map((time, index) => (
        <div
          className={`time-slot ${selectedSlot === time ? 'selected-time-slot' : ''}`}
          key={index}
          style={{
            // Add any styles you need here
          }}
          onClick={() => handleSlotSelection(time)}
        >
          {time}
        </div>
      ))}

    </div>
  ) : (
    <div className="text-center slotcenter">
      {translations.slot_unavailable}
    </div>
  )}
</div>


    <div className="col-md-12 d-none d-xl-block d-md-block text-center book-appointment mt-2">
      <button
        className="custom-button"
        onClick={() => setLoginModalVisibility(true)}
        disabled={isButtonDisabled}
        style={buttonStyle}
      >
        {translations.book_appointment}
      </button>
    </div>
  </div>
) : moduleType === "token" ? (
  <div className="token-prompt">
    <p>
      Next Available Token to book for <text>{locationName}</text>
    </p>
    <button 
      className="btn-token" 
      onClick={() => { 
        
        setLoginModalVisibility(true); 
      }}
    >
      Get Token
    </button>
  </div>
) : (
  <div>
    <div className="d-flex mt-4">
      {['morning', 'afternoon', 'evening', 'night'].map((timeCategory) => (
        <div
          key={timeCategory}
          className="col-md-3 custom-col text-center"
          onClick={() => handleTimeCategoryChange(timeCategory)}
          style={{
            cursor: "pointer",
            background: selectedTimeCategory === timeCategory ? "#1D3557" : "transparent",
            color: selectedTimeCategory === timeCategory ? "white" : "black",
            WebkitTextFillColor: selectedTimeCategory === timeCategory ? "white" : "#1D3557"
          }}
        >
          {translations[timeCategory]} <span className="time-category-icons">
            <i className={`fas ${timeCategory === 'morning' ? 'fa-cloud-sun' : timeCategory === 'afternoon' ? 'fa-sun' : timeCategory === 'evening' ? 'fa-cloud-moon' : 'fa-moon'} my-icon`}></i>
          </span>
        </div>
      ))}
    </div>

    <div className="time-slot-container mt-3">
    {advancebookingflag ? (
          filteredTimeSlots.length > 0 ? (
            <div className="d-flex flex-wrap justify-content-center mt-2">
              {filteredTimeSlots.map((time, index) => (
                <div
                  key={index}
                  className={`time-slot ${selectedSlot === time ? 'selected-time-slot' : ''}`}
                  style={{
                    // Add any styles you need here
                  }}
                  onClick={() => handleSlotSelection(time)}
                >
                  {time}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center slotcenter">
              {translations.slot_unavailable}
            </div>
          )
        ) : (
          <div className="text-center slotcenter">{
            advanceMsg
          }
          </div>
        )}
    </div>
    <div className="col-md-12 d-none d-xl-block d-md-block text-center book-appointment mt-2">
      <button
        className="custom-button"
        onClick={() => handleBookAppointment()}
        disabled={isButtonDisabled}
        style={buttonStyle}
      >
        {translations.book_appointment}
      </button>
    </div>
    
  </div>
)}



      {isLoginModalVisible && (
            <div>  <LoginModal onClose={() => setLoginModalVisibility(false)} /> </div>
        )}
    </>

  
  );
};


export default Example;     
