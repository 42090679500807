
import React, { useState, useEffect} from 'react';
import './catagories.css';
import { useServiceContext } from '../contextapi/Servicecontext';
import { Link, useParams,useLocation } from 'react-router-dom';
import apiservice from '../../apiServices';


export default function ServiceCategories() {

    const { selectedServices, addService, removeService, serviceIcons, setServiceIds, showOffer,toggleServiceIcon,showstaff, setShowstaff,setStaffLists,language,translations, setTotalservice, categoryIds, serviceIds , setShowoffer } = useServiceContext();
    const [categoryData, setCategoryData] = useState([]);
    const [isContentVisible, setIsContentVisible] = useState([0]);
    const [arrowRotations, setArrowRotations] = useState([0]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [show, setShowAll] = useState(0);
    const [isLoading,setIsLoading] = useState(false)
    var filteredData = []; // Normal variable for filtered data
    const styles = {
        hi: {
            fontSize: '1em', // Larger font size for Devanagari
            fontFamily: 'Noto Sans Devanagari, sans-serif', // Ensure the font family supports Devanagari script
        },
        gu: {
            fontSize: '1.2em', // Different size for Gujarati
            fontFamily: 'Noto Sans Gujarati, sans-serif', // Ensure the font family supports Gujarati script
        },
        default: {
            fontSize: '1em', // Default size for other scripts
        },
    };

    const appliedStyle = language === 'hi' ? styles.hi : language === 'gj' ? styles.gu : styles.default;
    const devanagariDigits = ['०', '१', '२', '३', '४', '५', '६', '७', '८', '९'];
    const gujaratiDigits = ['૦', '૧', '૨', '૩', '૪', '૫', '૬', '૭', '૮', '૯'];
    const convertToDevanagari = (num) => {
    return num.toString().split('').map(digit => devanagariDigits[digit] || digit).join('');
    };

    const convertToGujarati = (num) => {
    return num.toString().split('').map(digit => gujaratiDigits[digit] || digit).join('');
    };

const formatNumber = (num, lang) => {
    switch (lang) {
        case 'hi':
            return convertToDevanagari(num);
        case 'gj':
            return convertToGujarati(num);
        case 'en':
        default:
            return new Intl.NumberFormat('en-US', { useGrouping: false }).format(num);
    }
};

    const handleCategorySelect = (categoryIndex) => {
        setSelectedCategory(categoryIndex);
    };

    const { locationId } = useParams();
    let location = useLocation();
    const numericPart = locationId && locationId.match(/\d+$/);
    const loc_id = numericPart ? numericPart[0] : null;
    const location_id = {

        'location_id': loc_id
    };

    const handleAddService = (categoryIndex, serviceIndex) => {
        const isServiceAlreadySelected = selectedServices.some(service => service.serviceIndex === serviceIndex);
            if (!isServiceAlreadySelected) {
            const updatedCategoryData = [...categoryData];
                const selectedCategories = updatedCategoryData.flatMap(category => category.services)
                .filter(service => service.categoryIndex !== undefined);
                const selectedService = selectedCategories.find(serv => serv.serviceIndex === serviceIndex);
                const updatedService = { ...selectedService, location_id: location_id, categoryIndex: categoryIndex };
                addService(updatedService);
        }
    };
    

    const handleRemoveService = (categoryIndex, serviceIndex) => {
        setShowoffer(false)
        const updatedCategoryData = [...categoryData];
        const updatedCategoryDatas = updatedCategoryData.flatMap(category => category.services)  // Get all services from all categories
            .filter(service => service.categoryIndex !== undefined);
        const service = updatedCategoryDatas.find(serv => serv.serviceIndex == serviceIndex);
        removeService(service);
    };

    const toggleContent = (index) => {

        const updatedIsContentVisible = [...isContentVisible];
        updatedIsContentVisible[index] = !updatedIsContentVisible[index];
        setIsContentVisible(updatedIsContentVisible);
        const updatedArrowRotations = [...arrowRotations];
        updatedArrowRotations[index] = updatedIsContentVisible[index] ? 180 : 0;
        setArrowRotations(updatedArrowRotations);
    };

    const fetchData = async () => {
        setIsLoading(true)

        if ( loc_id !== undefined) {
        const api = process.env.REACT_APP_API_URL + 'getServicesAndCategory';

        try {

            const responseData = await apiservice(api, 'POST', { location_id: loc_id });
                if (responseData.status === 'true' && responseData.data) {
                    let transformedCategoryData = [];
                    for (const category of Object.keys(responseData.data)) {
                        const categoryData = responseData.data[category];
                        const transformedServices = categoryData.map((service) => ({

                            serviceIndex: service.service_id,
                            service_name: service.service_name,
                            cost_type: service.cost_type,
                            cost: service.cost,
                            categoryIndex: service.SC_ID,
                            address_required: service.address_required,
                            category_name: category,
                            discount_cost: service.discount_cost,
                            duration: service.duration,
                            description: service.service_desc,

                        }));

                        transformedCategoryData = [...transformedCategoryData, {
                            category,
                            services: transformedServices,
                        }];
                    }
                    setCategoryData((prevCategoryData) => prevCategoryData.concat(transformedCategoryData));
                }
                if (responseData.showStaff === 'yes') {
                    setShowstaff(true);
                }
                else {
                    console.error('API request returned an error');
                }
            
        } catch (error) {

            console.error('An error occurred while fetching data:', error);
        }finally{
            setIsLoading(false)
        }
    }
    
    };

    const ServiceId = selectedServices.map((service) => service.serviceIndex).filter(index => index !== undefined)

    const fetchStaff = async () => {
        const api = process.env.REACT_APP_API_URL + 'getStaffList';

        try {
            const responseData = await apiservice(api, 'POST', { ServiceId });
                if (responseData.status === 'true') {
                    const staffList = responseData.StaffList.map((staff) => {
                        return {
                            ...staff,
                            imagePath: responseData.imagePath,
                            profilePic: staff.profilePic || responseData.defaultProfilePic,
                        };
                    });
                    setStaffLists(staffList);
                    sessionStorage.setItem('staffList', JSON.stringify(staffList));
                } else {

                    console.warn('No staff is working for the selected service');
                }

        }
        catch (error) {

            console.error('An error occurred while fetching data:', error);
        }
    };

    function handlestaff() {
        fetchStaff()
    }
    const serviceIndexCount = selectedServices.filter(service => service.serviceIndex !== undefined).length;
    const isNextButtonEnabled = serviceIndexCount > 0;

    useEffect(() => {

        sessionStorage.setItem('location_id', loc_id);


        fetchData();
        return () => { 
        };
    }, [])

    const allServiceIndices = categoryData.flatMap(category => category.services.map(service => service.serviceIndex));
    const filteredServiceIndices = allServiceIndices.filter(index => index !== undefined && index !== null);
    setTotalservice(filteredServiceIndices.length)
    const servicenumber = sessionStorage.getItem('serviceId')
    const targetService = categoryData
        .flatMap((item) => item.services) // Flatten the nested services arrays
        .find((service) => service.serviceIndex == servicenumber); // Find the service with the given index

    const updatedtargetservice = [{ ...targetService, location_id: location_id }]
    const urlParams = new URLSearchParams(window.location.search);
    const serviceId = urlParams.get('serviceId')

  if(serviceId != undefined){
       
    sessionStorage.setItem('selectedServices', JSON.stringify(updatedtargetservice))
  }
    const defaultnumber = categoryData.flatMap(category => category.services)
    sessionStorage.setItem('defaultnumber', defaultnumber[0]?.categoryIndex)
    const getUniqueCategoryIndices = (data) => {
        const indices = new Set(); // To ensure uniqueness
        data?.forEach((category) => {
            category?.services.forEach((service) => {
                if (service.categoryIndex !== undefined) {
                    indices.add(service.categoryIndex);     
                }
            });
        });
        return Array.from(indices)[0];
    };

    useEffect(() => {

        setTimeout(() => {

            const rawCategoryNumber = sessionStorage.getItem('selectedServices');
            const urlParams = new URLSearchParams(window.location.search);
            const serviceId = urlParams.get('serviceId')

            if (rawCategoryNumber && serviceId != null) {
                const categoryNumber = JSON.parse(rawCategoryNumber);

                setSelectedCategory(categoryNumber[0]?.categoryIndex) // Should output the stored array
                toggleContent(categoryNumber[0]?.categoryIndex);

                setTimeout(() => {

                    toggleServiceIcon(categoryNumber[0]?.categoryIndex, categoryNumber[0]?.serviceIndex, true)

                }, 100);
            } else {
                setSelectedCategory(sessionStorage.getItem('defaultnumber'))

            }
        }, 1000);

    }, [])

    const matchingServices = categoryData
        .flatMap(category => category.services)  // Get all services from all categories
        .filter(service => service.categoryIndex == selectedCategory);

    const filterData =  (categoryData, categoryIndices, serviceIndices) => {
        const copiedCategoryData = JSON.parse(JSON.stringify(categoryData));
        return copiedCategoryData?.map(category => {

            if (category.services && category.services.length > 0) {
                category.services = category.services.filter(service => {
                    return categoryIndices?.includes(service.categoryIndex) && serviceIndices?.includes(service.serviceIndex);
                });
                return category.services.length > 0 ? category : null;

            } else {
                return null; // Return null if no services are present
            }
        }).filter(category => category !== null); // Remove null categories
    };

    if (serviceIds != null) {

        var filteredData = filterData(categoryData, categoryIds, serviceIds);
        var indexs = filteredData[0]?.services[0]?.categoryIndex;
        sessionStorage.setItem('defaultnumber', indexs)
    }

    else {

        filteredData = categoryData
    }

    function handleshowall() {
        setShowAll(1)
        setServiceIds(null)

    }
   
    useEffect(() => {
        if (categoryData.length === 1 && categoryData[0]?.services.length === 1) {
            const preselectedService = categoryData[0]?.services[0];
            setSelectedCategory(preselectedService.categoryIndex);
            toggleContent(preselectedService.categoryIndex);
            toggleServiceIcon(preselectedService.categoryIndex, preselectedService.serviceIndex, true);
            handleAddService(preselectedService.categoryIndex , preselectedService.serviceIndex);
            sessionStorage.setItem('selectedServices', JSON.stringify([{ ...preselectedService, location_id: location_id }]));
        } else {
            setSelectedCategory(sessionStorage.getItem('defaultnumber'));
        }
    }, [categoryData]);

    return (
        <div className='row'>
            {isLoading ? (
                <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: '56px',
                    width: '56px',
                    animation: 'rotate 2s linear infinite',
                    marginTop: '80px'
                }} className='loaders loader-overlay'>
                </div>
                <div className='loaders-overlay' style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '12px',
                }}>
                    <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/whizzq-icon/whizzqlogo_dec23.png" width='120px' alt="Loading..." />
                </div>
            </div>
            ) : (
                <>
            <div className='col-md-5 d-none d-md-block'>
                <div className='category-tabs scrollable-servicecat'>
                    {filteredData.map((category) => (
                        <div
                            className={`category-tab ${getUniqueCategoryIndices([category]) == selectedCategory ? 'selected' : ''}`}
                            key={getUniqueCategoryIndices([category])}
                            onClick={() => handleCategorySelect(getUniqueCategoryIndices([category]))}
                        >
                            {category.category}

                        </div>

                    ))}
                    {show == 0 && serviceIds?.length > 0 && <div onClick={handleshowall}>see all categories...</div>}


                </div>
            </div>
            <div className='col-md-7  d-none d-md-block'>
                    <>
                {true && true && (
                    <div className='service-list scrollable-servicecat '>
                        {matchingServices?.map((service, index, categoryIndex) => (
                            <div key={index} className='service d-flex row'>
                                <div className='col-md-4'>{service.service_name} 
                                     </div>
                                <div className='col-md-3'><span className=''>{<i className="fa-solid fa-clock"></i> }</span><span  style={appliedStyle}>{formatNumber(service.duration, language) }</span><span> {' mins '}</span> </div>
                                <div className='col-md-3'>   {service.cost_type} <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/Vector+(1).svg"></img>{showOffer  &&  service.discount_cost > 0 ? (
                                   <div> <span className='cost'>{service.cost} </span> <span>{service.discount_cost}</span> </div>
                                ) : (
                                    <span>{service.cost}</span>
                                )}
                                </div>
                                <div className='add-service col-md-2' onClick={() => toggleServiceIcon(selectedCategory, service.serviceIndex, false)}>
                                    {serviceIcons[selectedCategory] && serviceIcons[selectedCategory][service.serviceIndex] ? (
                                        <div className='remove-icon' onClick={() => handleRemoveService(selectedCategory, service.serviceIndex)}>
                                            <img
                                                src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/-.svg"
                                                alt=""
                                            />
                                        </div>
                                    ) : (
                                        <div className='add-icon' onClick={() => handleAddService(selectedCategory, service.serviceIndex)}>
                                            <img
                                                src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/%2B.svg"
                                                alt=""
                                            />
                                        </div>
                                    )}
                                </div>
                                
                            </div>
                        ))}
                    </div>
                )}
                </>
            </div>
            
            
            <div className='scrollable-cat  d-block d-md-none '>
                {filteredData.map((category, categoryIndex) => (
                    <div key={getUniqueCategoryIndices([category])} className='card bg-light mt-2 p-2 mb-2 '>
                        <div className='d-flex justify-content-between mt-2' onClick={() => toggleContent(getUniqueCategoryIndices([category]))}
                        >
                            <div className='d-flex align-items-center'>
                                <div className='category-contents'>
                                    <div className='category-heading'>{category.category}</div>
                                    <div className='category-subheading'>{category.services.length} {translations.services}</div>
                                </div>
                            </div>
                            <div
                                className="arrow-container text-end"

                                style={{
                                    transform: `rotate(${arrowRotations[getUniqueCategoryIndices([category])]}deg)`,
                                    transformOrigin: 'center',
                                    marginBottom: '6px'


                                }}

                            >
                                <div
                                > <i className="fa-solid fa-chevron-down"></i>
                                </div>
                            </div>
                        </div>

                        {isContentVisible[getUniqueCategoryIndices([category])] && (
                            <div className='row g-2'>
                                {category.services.map((service, serviceIndex) =>

                                (
                                    <div key={service.serviceIndex} className='col-md-6'>
                                        <div className='card d-flex flex-row align-items-center justify-content-between p-2 mt-2 '>
                                            <div className=''>
                                                <div className='service-type'>{service.service_name}</div>
                                                <div className='service-sub-type'>
                                                    {service.cost_type} - {service.category_name}
                                                </div>
                                                <div><span className=''>{<i className="fa-solid fa-clock"></i>}</span><span>{formatNumber(service.duration, language)}</span><span>{' mins '}</span> </div>
                                                <div className='service-sub-type'>
                                                 <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/Vector+(1).svg"></img>{showOffer  &&  service.discount_cost > 0 ? (
                                    <span className='cost'>{service.cost}  {service.discount_cost}</span>
                                ) : (
                                    <span>{service.cost}</span>
                                )}
                                                </div>
                                            </div>

                                            <div className='add-service' onClick={() => {

                                                toggleServiceIcon(getUniqueCategoryIndices([category]), service.serviceIndex, false)
                                            }}>
                                                {serviceIcons[getUniqueCategoryIndices([category])] && serviceIcons[getUniqueCategoryIndices([category])][service.serviceIndex] ? (
                                                    <div className='remove-icon' onClick={() => handleRemoveService(getUniqueCategoryIndices[category], service.serviceIndex)}

                                                    > <img
                                                            src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/-.svg"
                                                            alt=""
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className='add-icon' onClick={() => handleAddService(getUniqueCategoryIndices([category]), service.serviceIndex)}
                                                    >
                                                        <img
                                                            src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/%2B.svg"
                                                            alt=""
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    
                ))}
                 {show == 0 && serviceIds?.length > 0 && <div onClick={handleshowall}>see all categories...</div>}

            </div>

            {<div className='col-md-12 d-none d-xl-block d-md-block text-center service-button mt-1'>
                < Link to={showstaff ? (`${location.pathname}/staff`) : (`${location.pathname}/timeslot`)}><button type="button" className="next-button " onClick={handlestaff}

                    disabled={!isNextButtonEnabled}

                >
                    {translations.next}
                </button> </Link>
            </div>}
        
        </>
        )}
        </div>
    );
}























