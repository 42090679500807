import React, { useState } from 'react';
import './businessprofile.css';
import { useEffect } from 'react';
import { useServiceContext } from '../contextapi/Servicecontext';
import { FaInstagram, FaFacebook, FaTwitter } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

export default function BusinessProfile({}) {

    const { businessProfile, setModuleType,setUrl ,setLocationName, setDescription,totalservice ,setBusinessId ,setQueue,setLocationId , setBusinessProfile
    } = useServiceContext();
      
    const [from , setFrom] = useState();
    const [toTime ,setToTime] = useState();
    const [isHoliday , setIsHoliday] =  useState();
    const [holidayDescription , setHolidayDescription] =  useState();
    const { instagram_link, facebook_link, twitter_link } = businessProfile;
    

    const locationid = sessionStorage.getItem('businesslocationId')

    const convertTo12HourFormat = (time24) => {
        
        const [hours, minutes] = time24.split(':').map(Number);
        const period = hours >= 12 ? 'PM' : 'AM';
        const hours12 = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

        return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
    };

    const from12Hour = from ? convertTo12HourFormat(from) : '';
    const to12Hour = toTime ? convertTo12HourFormat(toTime) : '';


useEffect(() => {

    const timer = setTimeout(() => {
        const storedBusinessProfileString = sessionStorage.getItem('businessProfile');
        
        if (storedBusinessProfileString) {
            // Parse the string into an object
            const storedBusinessProfile = JSON.parse(storedBusinessProfileString);
            setBusinessProfile(storedBusinessProfile);

            // Now you can access properties directly
            setBusinessId(storedBusinessProfile.locationId);
            setQueue(storedBusinessProfile.module);
            setLocationId(storedBusinessProfile.locationId);
            setDescription(storedBusinessProfile.listing_description);
            setUrl(storedBusinessProfile.listing_url);
            setModuleType(storedBusinessProfile.module);
            setLocationName(storedBusinessProfile.locationName);
            setIsHoliday(storedBusinessProfile.isHoliday);
            setHolidayDescription(storedBusinessProfile.holidayDescription);
            setFrom(storedBusinessProfile.frmTime);
            setToTime(storedBusinessProfile.toTime);
        }
           
    }, 500);
   
    return () => clearTimeout(timer);
}, []);






    return (
        <>
        <div className="d-flex border border-gray bg-white p-3">
            <div className="businessprofile-image ">
                <img  src= { businessProfile.locationImage ? businessProfile.locationImage : 'https://whizzdatalocal.s3-us-west-2.amazonaws.com/business_images/business_default.png'}
                    className="img-fluid me-3"
                    alt="Business Image"
                />
            </div>
            <div className="col-md-10">
                <div>
                <div className="d-flex justify-content-between align-items-center">
        <div className="b-title">{businessProfile.locationName}</div>
        <div className="social-media-buttons">
        {instagram_link && (
  <a href={instagram_link} target="_blank" rel="noopener noreferrer">
    <button className="btn btn-instagram" style={{ color: 'white' }}>
      <img 
        src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/ic_instagram.png" 
        alt="Instagram" 
        width="35" 
        height="35" 
        style={{ verticalAlign: 'middle' }}
      />
    </button>
  </a>
)}

{facebook_link && (
  <a href={facebook_link} target="_blank" rel="noopener noreferrer">
    <button className="btn btn-facebook">
      <img 
        src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/ic_facebook.png" 
        alt="Facebook" 
        width="35" 
        height="35" 
        style={{ verticalAlign: 'middle' }}
      />
    </button>
  </a>
)}

{twitter_link && (
  <a href={twitter_link} target="_blank" rel="noopener noreferrer">
    <button className="btn btn-twitter">
      <img 
        src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/ic_twitter.png" 
        alt="Twitter" 
        width="35" 
        height="35" 
        style={{ verticalAlign: 'middle' }}
      />
    </button>
  </a>
)}
        </div>
    </div>
                    <div className="card-address"><span > <i className="fa fa-location-dot"></i> {businessProfile.locationAddress + ',' + businessProfile.locationCity + ',' + businessProfile.locationState}</span> </div>
                  { isHoliday ?(<div>{holidayDescription}</div>) : (<div className="card-timing"><span> <i className="fa  fa-clock"></i> </span>  <span className='card-bold'>Working hours:</span> <span className="card-address">{from12Hour + " to " + to12Hour} </span> </div> ) }

                    <div className="card-timing"> <span><i className="fa-solid fa-gear"></i> </span> <span className='card-bold'> Total Services:</span><span className="card-address"> {totalservice} </span></div>
                </div>
            </div>
        </div>
        
        
    </>

    );
}
