import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { ServiceProvider } from './Components/contextapi/Servicecontext';

ReactDOM.render(
  <ServiceProvider>
   <App/>
  </ServiceProvider>

  ,
  document.getElementById('root')
);

