import React, { useState } from 'react';
import AppleLogin from 'react-apple-login';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import apiservice from '../../apiServices';
import './usermodal.css'

const AppleLoginButton = ({ setLoginModalVisibility }) => {
  const [showModal, setShowModal] = useState(false);  // To control modal visibility
  const [decodedToken, setDecodedToken] = useState();
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    mobile: ''
  }); // User details from input fields
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    mobile: ''
  }); // Error messages for validation

  const handleLoginSuccess = async (response) => {


    try {
      // Decode the Apple ID token to get user details
      setShowModal(true);

      const decodedToken = jwtDecode(response.authorization?.id_token);
      console.log('Decoded Apple Token:', decodedToken);
      setDecodedToken(decodedToken)

      // Show modal to collect additional information (name, mobile)


      // Store the unique Apple identifier (sub) in cookies
    } catch (error) {
      console.error('Error during Apple login:', error);
    }
  };

  const handleLoginFailure = (error) => {
    console.error('Apple Sign-In Error:', error);
  };

  // Validate form fields (first name, last name, mobile)
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate first name
    if (!userDetails.firstName.trim()) {
      newErrors.firstName = 'First name is required.';
      isValid = false;
    }

    // Validate last name
    if (!userDetails.lastName.trim()) {
      newErrors.lastName = 'Last name is required.';
      isValid = false;
    }

    // Validate mobile number (must be 10 digits)
    if (userDetails.mobile.trim() && !/^\d{10}$/.test(userDetails.mobile)) {
      newErrors.mobile = 'Mobile number must be 10 digits.';
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  // Handle the form submission after validation
  const handleSubmit = async () => {
    if (!validateForm()) return;  // If validation fails, prevent submission

    const { firstName, lastName, mobile } = userDetails;

    try {
      // API URL for logging in or registering the user with additional details
      const api = process.env.REACT_APP_API_URL + 'LoginOrRegisterWithSocial';
      const data = await apiservice(api, 'POST', {
        sub: Cookies.get('appleSub'), // Apple's unique identifier for the user
        email: decodedToken.email,
        name: firstName + ' ' + lastName,
        mobile,
        for_what: 'apple',
        picture: "",  // Optionally, handle the picture if provided
      });

      // Set cookies with the response data
      Cookies.set('bookingToken', data?.token, { expires: 30 });
      Cookies.set('firstname', firstName, { expires: 30 });
      Cookies.set('lastname', lastName, { expires: 30 });
      Cookies.set('mobile', mobile, { expires: 30 });

      // Save data to localStorage
      localStorage.setItem('userId', JSON.stringify(data?.userId));
      localStorage.setItem('mobileNumber', JSON.stringify(mobile));

      // Close the modal after successful submission
      setShowModal(false);
      setLoginModalVisibility(false)

      // Optionally close the login modal (if provided)

    } catch (error) {
      console.error('Error during user details submission:', error);
    }
  };

  console.log('showmodal', showModal)

  return (
    <>
      <div>
        <AppleLogin
          clientId="app.whizzq.stagingbiz" // Your client ID from Apple Developer Portal
          redirectURI="https://stagingbiz.whizzq.app/l" // Secure HTTPS URL
          scope="name email"
          responseType="code id_token" // We need both 'code' and 'id_token' for full information
          responseMode="form_post"
          usePopup={true} // Open sign-in in a popup window
          callback={handleLoginSuccess}
          onFailure={handleLoginFailure}
          render={renderProps => (
            <button
              onClick={renderProps.onClick}
              style={{
                backgroundColor: "white",
                padding: 9,
                border: "1px solid black",
                borderRadius: '2px',
                fontFamily: "none",
                fontSize: "12px",
                fontFamily: 'Poppins'
              }}
            >
              <i className="fa-brands fa-apple px-2 "></i>
              Continue with Apple
            </button>
          )}
        />
        {showModal && (<div className="modals">
          <div className="modal-content">
            <div>
              <label>First Name:</label>
              <input
                type="text"
                onChange={(e) => setUserDetails({ ...userDetails, firstName: e.target.value })}
                placeholder='Enter your first name'
                required
              />
              {errors.firstName && <p className="error">{errors.firstName}</p>} {/* Error message */}
            </div>
            <div>
              <label>Last Name:</label>
              <input
                type="text"
                onChange={(e) => setUserDetails({ ...userDetails, lastName: e.target.value })}
                placeholder='Enter your last name'
                required
              />
              {errors.lastName && <p className="error">{errors.lastName}</p>} {/* Error message */}
            </div>
            <div>
              <label>Mobile Number:</label>
              <input
                type="text"
                value={userDetails.mobile}
                onChange={(e) => setUserDetails({ ...userDetails, mobile: e.target.value })}
                placeholder='Enter your Mobile No.'
              />
              {errors.mobile && <p className="error">{errors.mobile}</p>} {/* Error message */}
            </div>
            <div>
              <button onClick={() => {
                setShowModal(false);
                setLoginModalVisibility(false);
              }} className='cancel'>
                Cancel
              </button>

              <button onClick={handleSubmit} className='submit'>Submit</button>
            </div>
          </div>
        </div>)}
      </div>
    </>
  );
};

export default AppleLoginButton;
