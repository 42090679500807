// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.business-banner .carousel-inner img {
    
  max-width: 110%; /* Ensure image doesn't exceed container width */
  height: auto;
  object-fit: contain;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5); /* Example: Darken control icons */
  border-radius: 50%; /* Example: Make control icons circular */
}
`, "",{"version":3,"sources":["webpack://./src/Components/BusinessBanner/Businessbanner.css"],"names":[],"mappings":";;AAEA;;EAEE,eAAe,EAAE,gDAAgD;EACjE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;;EAEE,oCAAoC,EAAE,kCAAkC;EACxE,kBAAkB,EAAE,yCAAyC;AAC/D","sourcesContent":["\n\n.business-banner .carousel-inner img {\n    \n  max-width: 110%; /* Ensure image doesn't exceed container width */\n  height: auto;\n  object-fit: contain;\n}\n\n.carousel-control-prev-icon,\n.carousel-control-next-icon {\n  background-color: rgba(0, 0, 0, 0.5); /* Example: Darken control icons */\n  border-radius: 50%; /* Example: Make control icons circular */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
