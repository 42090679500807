// apiService.js
 const apiservice = async (url, method = 'GET', data = {}, headers = {}, abortSignal = undefined) => {
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
        signal: abortSignal
      },
    };
  
    // Only include the body if it's not a GET request
    if (method !== 'GET' && data) {
      options.body = JSON.stringify(data);
    }
  
    try {
      const response = await fetch(url, options);
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      return result;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.warn('Request was aborted');
      } else {
        console.error('Error fetching or parsing data:', error);
      }
      throw error;
    }
  };
  
  export default apiservice;
  