import React, { useRef, useEffect, useState } from "react";
import './location.css';
import { useServiceContext } from "../contextapi/Servicecontext";
import debounce from 'lodash/debounce'; // Import debounce function from lodash

const AutoComplete = ({ onSelect, setIsAutoCompleteVisible , closelocation }) => {

  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const distanceRef = useRef();

  const { selectedDistance, setSelectedDistance, selectedLocation, setStartIndex, translations , selectedlocation, setSelectedlocation } = useServiceContext();
  const [tempSelectedDistance, setTempSelectedDistance] = useState(selectedDistance);
  
  const options = {
    componentRestrictions: { country: "in" },
    fields: ["address_components", "geometry", "icon", "name"],
  };

  useEffect(() => {
    const handlePlaceChanged = async function () {

      try {

        await new Promise(resolve => setTimeout(resolve, 0));
        const place = autoCompleteRef.current.getPlace();

        if (!place || !place.geometry) {
          console.error("Empty or invalid place object:", place);
          return;
        }

        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();

        const newSelectedLocation = {
          name: place.name || '',
          latitude,
          longitude,
        };

        setSelectedlocation(newSelectedLocation);

        localStorage.setItem('long', JSON.stringify(newSelectedLocation.longitude))
        localStorage.setItem('lat', JSON.stringify(newSelectedLocation.latitude))
        localStorage.setItem('place', JSON.stringify(newSelectedLocation.name))

        if (typeof onSelect == 'function') {
          onSelect(newSelectedLocation);
          setStartIndex(0);
        } else {
          console.error("onSelect is not a function");
        }
      } catch (error) {
        console.error("Error processing place_changed event:", error);
      }
    };

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    const debouncedPlaceChanged = debounce(handlePlaceChanged, 1); // Debounce handlePlaceChanged function
    const listener = autoCompleteRef.current.addListener("place_changed", debouncedPlaceChanged);

    return () => {
      window.google.maps.event.removeListener(listener);
      autoCompleteRef.current = null;
    };
  }, []);

  const handleDistanceChange = () => {
    setTempSelectedDistance(distanceRef.current.value);
  };

  const handleDistanceKeyUp = () => {
    setSelectedDistance(tempSelectedDistance);
    
  
    setStartIndex(0);
   
  };

  const handlelocation = () =>{
    setIsAutoCompleteVisible(false);

  }

  return (
    <>
      <div className="auto-suggest" onMouseLeave={closelocation}>
        <div className="location-input">
          <input 
            ref={inputRef} 
            type="search" 
            id="location" 
            placeholder={translations.Enterlocation} 
            value={selectedlocation.name} 
            onChange={(e) => setSelectedlocation({ ...selectedLocation, name: e.target.value })}
          />
        </div>
        <input
          ref={distanceRef}
          type="range"
          id="distance"
          name="distance"
          min="5"
          max="100"
          value={tempSelectedDistance}
          onChange={handleDistanceChange}
          onMouseUp={handleDistanceKeyUp}
          onTouchEnd={handleDistanceKeyUp}
          placeholder={translations.selectlocation}
        />
        <div   className="distance"> <span>{tempSelectedDistance} km</span> <span style={{ float : 'right' , fontSize: '18px' , paddingRight: '26px' }}  onClick={handlelocation} ><i class="fa-solid fa-xmark"></i></span> </div> 
      </div>
    </>
  );
};

export default AutoComplete;
