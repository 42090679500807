// razorpayConfig.js
export const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY_ID;

export const RAZORPAY_OPTIONS = {
  key: RAZORPAY_KEY,
  amount: 0, // Default amount; dynamically set it in your component
  currency: "INR",
  name: "Your Company Name",
  description: "Transaction",
  prefill: {
    name: "Customer Name",
    email: "customer@example.com",
    contact: "9999999999",
  },
  theme: {
    color: "#3399cc",
  },
};
