// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-btn button {
    background-color: #1D3557; /* Button color */
    font-family: 'Poppins';
    color: white;              /* Text color */
    border: none;
    padding: 8px 20px;
    font-size: 15px;
    border-radius: 8px;        /* Rounded corners */
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/Components/PaymentComponent/payment.css"],"names":[],"mappings":"AAGA;IACI,yBAAyB,EAAE,iBAAiB;IAC5C,sBAAsB;IACtB,YAAY,eAAe,eAAe;IAC1C,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB,SAAS,oBAAoB;IAC/C,eAAe;EACjB","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');\n\n\n.payment-btn button {\n    background-color: #1D3557; /* Button color */\n    font-family: 'Poppins';\n    color: white;              /* Text color */\n    border: none;\n    padding: 8px 20px;\n    font-size: 15px;\n    border-radius: 8px;        /* Rounded corners */\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
