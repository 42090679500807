import React from 'react'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';





export default function CityLandingpage() {
    const { city } = useParams();
    const apipath = process.env.REACT_APP_API_URL
    const stylepath = process.env.REACT_APP_STYLE_URL

    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);
    const [showTopBusiness, setShowTopBusiness] = useState(false); // State to control when to show the component

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const storedLat = localStorage.getItem('lat');
            const storedLong = localStorage.getItem('long');
            setLat(storedLat);
            setLong(storedLong);
        }, 3000);
        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const delayId = setTimeout(() => {
            setShowTopBusiness(true);
        }, 3000); // 4000 ms = 4 seconds

        return () => clearTimeout(delayId);
    }, []);

    return (
        <><div>
            <div> <web-banner-component bannerApi={apipath + "banner"} styleLink={stylepath}> </web-banner-component></div>
            <div></div> <web-services-component servicesApi={apipath + "getServiceType"} styleLink={stylepath}></web-services-component>

            <div> <web-topcategory-component topcategoryapi={apipath + "getServiceType"} styleLink={stylepath}></web-topcategory-component></div>
            <div>
                {showTopBusiness && (
                    <web-topbusiness-component
                        lat={lat}
                        long={long}
                        getTopBusiness={apipath + "getTopBusinessByCity"}
                        styleLink={stylepath}
                    ></web-topbusiness-component>
                )}
            </div>
            <div><web-citylist-component
                localityApi={apipath + "CityWiseAreaBusinessCount"}
                styleLink={stylepath}
                city={city}

            >
            </web-citylist-component> </div>
            <div>  <web-downloadapp-component
                styleLink={stylepath}>
            </web-downloadapp-component> </div>
            <div> <web-footer-component
                styleLink={stylepath}>
            </web-footer-component>
            </div>
        </div>

        </>
    )
}
