import React, { useEffect } from 'react';
import './businesstab.css';
import ServiceCategories from '../Servicecatagories/Servicecatagories';
import { useNavigate, useLocation } from 'react-router-dom';
import BusinessOffer from '../BusinessOffer/BusinessOffer';
import { useServiceContext } from '../contextapi/Servicecontext';
import PhotoGallery from '../BusinesGallery/Gallery';
import About from './about';
import AppointmentCard from '../MyAppointments/Appointments';

export default function Businesstab() {
    const { activeTab, setActiveTab } = useServiceContext();
    const navigate = useNavigate();
    const location = useLocation();

    // Helper function to get query parameters
    const getQueryParams = () => {
        return new URLSearchParams(location.search);
    };

    // Check if discount-code is present in the URL
    useEffect(() => {
        const queryParams = getQueryParams();
        const discountCode = queryParams.get('discount-code');
        if (discountCode) {
            setActiveTab('offer');
        }
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        sessionStorage.setItem('selectedServices', JSON.stringify([]));
        sessionStorage.setItem('serviceIcons', JSON.stringify([]));
    };

    return (
        <div className="border border-gray bg-white p-3">
            <div className="row">
                <div className="col-md-12">
                    <div className="d-flex align-items-center mb- border-bottom">
                        <div onClick={() => handleTabClick('about')}>
                            <span onClick={() => navigate(-1)}><i className="fa-solid fa-arrow-left"></i></span>
                        </div>
                        <div className={`tab ${activeTab === 'about' ? 'active' : ''}`} onClick={() => handleTabClick('about')}>About</div>
                        <div className={`tab ${activeTab === 'service' ? 'active' : ''}`} onClick={() => handleTabClick('service')}>Services</div>
                        <div className={`tab ${activeTab === 'photos' ? 'active' : ''}`} onClick={() => handleTabClick('photos')}>Photos</div>
                        <div className={`tab ${activeTab === 'offer' ? 'active' : ''}`} onClick={() => handleTabClick('offer')}>Offers</div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="content-section">
                        {activeTab === 'about' && <About />}
                        {activeTab === 'service' && <ServiceCategories />}
                        {activeTab === 'photos' && <PhotoGallery />}
                        {activeTab === 'offer' && <BusinessOffer />}
                        {activeTab === 'appointments' && <AppointmentCard />}
                    </div>
                </div>
            </div>
        </div>
    );
}
