// LoginModal.js
import React, { useState, useEffect } from "react";
import "./loginmodal.css";
import BasicOTPComponent from "./BasicOTPComponent";
import { useServiceContext } from '../contextapi/Servicecontext';
import BookingSuccessModal from "./BookingSucessModal";
import Cookies from "js-cookie";
import ReactGA from 'react-ga4';
import apiservice from '../../apiServices';




const LoginModal = ({ onClose }) => {


  const [loading, setLoading] = useState(false)
  const [otploader, setOtploader] = useState(false)
  const [onbutton, setOnbutton] = useState(false)


  const { bookingdata, setBookingdata, abled, selectedServices,translations, lastName, setLastName,queue ,setUserId  , bookingSource} = useServiceContext();


  const [firstName, setFirstName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [userdetails, setUserdetails] = useState([]);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [isMobileError, setIsMobileError] = useState('');
  const [isFirstNameError, setIsFirstNameError] = useState('');
  const [isLastNameError, setIsLastNameError] = useState('');
  const [invalidotp, setInvalidotp] = useState([])
  const [qrimage, setQrimage] = useState();
  const [message, setMessage] = useState();
  const [reference, setReference] = useState();
  const [address, setAddress] = useState("")
  const [IsAddressError, setIsAddressError] = useState();
  const [bookingfor, setBookingfor] = useState('self');
  const [listImage, setListImage] = useState(null)
  const [errorMessage, setErrorMessage] = useState('');
  const[tokenNumber,setTokenNumber] = useState();



  let anyRequiresAddress = false;

  const bookingToken = Cookies.get('bookingToken')

  for (const service of selectedServices) {
    if (service.address_required === 1) {
      anyRequiresAddress = true;
      break;
    }
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxSizeInBytes = 5 * 1024 * 1024; // 10 MB

    if (!selectedFile) {
      setErrorMessage('Please select a file.'); // Set error message if no file is selected
      return;
    }

    const fileType = selectedFile.type;
    if (
      (fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png') &&
      selectedFile.size <= maxSizeInBytes
    ) {
      setListImage(selectedFile);
      setErrorMessage(''); // Clear error message if file is valid
    } else {
      let errorMessageText = 'Please upload an image in JPG, JPEG, or PNG format';
      if (selectedFile.size > maxSizeInBytes) {
        errorMessageText += ' not exceeding 10 MB in size.';
      }
      // Set error message
      setErrorMessage(errorMessageText);

      e.target.value = null;
    }
  };



  const handleable = (abled) => {
    setOnbutton(abled >= 3);
  };

  const isValidIndianMobileNumber = (number) => {

    const mobileRegex = /^[6-9]\d{9}$/;
    return mobileRegex.test(number);
  };

  const isvalidname = (Name) => {

    const nameRegex = /^[A-Za-z]{2,}$/;
    return nameRegex.test(Name);
  }

  const isValidAddress = (Address) => {
    const usAddressRegex = /^[a-zA-Z0-9\s,/&-]+$/

    if (anyRequiresAddress) {
      return usAddressRegex.test(Address);
    }
    return true
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (!(file instanceof Blob)) {
        reject(new Error('Parameter is not a Blob'));
        return;
      }
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleSendOtp = async () => {

    const listImageBase64 = listImage ? await convertToBase64(listImage) : null;
    if (isValidIndianMobileNumber(mobileNumber.trim()) && isvalidname(firstName.trim()) && isvalidname(lastName.trim()) && isValidAddress(address.trim())) {

      if (queue === 'pickup' && !listImageBase64) {
        setErrorMessage('No file selected.'); // Set error message if no file is selected
        return;
      }

      try {

        setOtploader(true)
        const api = process.env.REACT_APP_API_URL + 'sendOTPConsumer';
        const data = await apiservice(api, 'POST', {
          mobileNumber: mobileNumber,
          firstName: firstName,
          lastName: lastName,
          address: address,
          listImage: listImageBase64,
        });

        setUserdetails(data);
        
        if(bookingfor !== 'other'){
          setUserId(data.userId)
          localStorage.setItem('userId', JSON.stringify(data.userId))
      }
        localStorage.setItem('mobileNumber', JSON.stringify(data.mobileNumber))

        if (data.status) {

          setOtpSent(true);
          setIsMobileValid(true);

          setBookingdata((prevBookingdata) => ({
            ...prevBookingdata,
            firstName: firstName,
            lastName: lastName,
            address: address,
            ...(listImageBase64 && { listImage: listImageBase64 }),
          }));
        }
        else {

          setIsMobileValid(false);
        }
        setOtploader(false)
      }
      catch (error) {
        console.error('Error calling sendOTPConsumer API:', error);
      }
    }

    else {

      setIsMobileError(isValidIndianMobileNumber(mobileNumber.trim()) ? '' : 'Enter correct mobile number');
      setIsFirstNameError(isvalidname(firstName.trim()) ? '' : 'Enter valid first name');
      setIsLastNameError(isvalidname(lastName.trim()) ? '' : 'Enter valid last name');
      setIsAddressError(isValidAddress(address.trim()) ? '' : 'Enter Valid Address')

    }
  };


  const confirmbooking = async () => {

    const bookingSource = sessionStorage.getItem('bookingSource')

    if (isValidAddress(address.trim())) {
      const listImageBase64 = listImage ? await convertToBase64(listImage) : null;

      if (queue === 'pickup' && !listImageBase64) {
        setErrorMessage('No file selected.'); // Set error message if no file is selected
        return;
      }

      const api = process.env.REACT_APP_API_URL + 'makeBooking';
      const userId = JSON.parse(localStorage.getItem('userId'))
      const mobilenumber = JSON.parse(localStorage.getItem('mobileNumber'))

      try {

        const listImageBase64 = listImage ? await convertToBase64(listImage) : null;

        setLoading(true)
        const responseData = await apiservice(api, 'POST', {
          ...bookingdata,
          firstName: firstName,
          lastName: lastName,
          address: address,
          token: bookingToken,
          book_for: bookingfor,
          userId: userId,
          mobileNumber: mobilenumber || mobileNumber,
          otp: bookingdata.otp || '',
          listImage: listImageBase64,
          booking_source_type: bookingSource,
        });
      
          ReactGA.event({
            category: 'User',
            action: 'Clicked a button'
          })
          setQrimage(responseData.qrcodeImage);
          setMessage(responseData.message);
          setReference(responseData.srt_reffrence_no);
          setTokenNumber(responseData.tokenNumber)

          if (responseData.status === 'false') {
            setInvalidotp(responseData.message)
          }

          if (responseData.status) {
            if (responseData.repeat_appointment){
                  setLoading(false)
                  setBookingSuccess(true)

            }

            const username = responseData.profile.username;
            const nameParts = username.split(' ');
            const firstName = nameParts[0];
            const lastName = nameParts.slice(1).join(' ');
          
            Cookies.set('bookingToken', responseData.token, { expires: 30 }); // Set expiry date as required
            Cookies.set('firstname', firstName, { expires: 30 }); // Set expiry date as required
            Cookies.set('lastname', lastName, { expires: 30 });
            Cookies.set('mobile', responseData.profile.mobile, { expires: 30 })

            setBookingSuccess(true);

            const partnerId = 20001541;
            const endpoint = process.env.REACT_APP_RWG_URL
            const rwgTokenCookie = Cookies.get('_rwg_token');
            const rwgTokenVal = rwgTokenCookie.split(';').find(row => row.trim().startsWith('_rwg_token=')).split('=')[1].trim();

            if (rwgTokenVal !== 'undefined' && rwgTokenVal !== null && rwgTokenVal !== '') {

              fetch(endpoint, {
                method: "POST",
                body: JSON.stringify({
                  conversion_partner_id: partnerId,
                  rwg_token: rwgTokenVal,
                  merchant_changed: 2
                })
              });
            }

            setBookingdata((prevBookingdata) => ({
              ...prevBookingdata,
              message: responseData.message,
              referenceNumber: responseData.srt_reffrence_no,
              qrcodeImage: responseData.qrcodeImage
            }));

          } else {
            console.warn('No staff is working for the selected service');
          }
       
        setLoading(false)


      } catch (error) {

        console.error('An error occurred while fetching data:', error);
      }

    }
    else {

      setIsAddressError(isValidAddress(address.trim()) ? '' : 'Enter Valid Address')

    }
  }

  useEffect(() => {
    handleable(abled);
  }, [abled]);


  useEffect(() => {

    const firstNameCookie = Cookies.get('firstname');
    const lastNameCookie = Cookies.get('lastname');
    const mobileCookie = Cookies.get('mobile')

    if (firstNameCookie && lastNameCookie) {

      setFirstName(firstNameCookie);
      setLastName(lastNameCookie);
      setMobileNumber(mobileCookie);

    }
  }, []);

  const renderContent = () => {


    const ButtonStyle = {
      margin: "40px 1px 12px", backgroundColor: ' #1D3557', width: '100%',
      padding: '11px'
    }

    const ButtonStyleotp = {
      width: '100%',
      backgroundColor: '#1D3557'

    };


    if (bookingSuccess) {


      return (
        <div>
          <div style={{ marginLeft: '120px' }}>

          </div>
          <BookingSuccessModal onClose={onClose} message={message} referenceNumber={reference} qrcodeImage={qrimage} />
        </div>
      );
    } else if (otpSent) {
      return (
        <div>
          <div className="modal-title">
            <span>{translations.step} 4/4 </span>
            <span style={{ paddingLeft: "74px" }}>{translations.verify}</span>
            <div style={{ marginLeft: '130px' }}>
              <span onClick={onClose}><i className="fas fa-xmark"></i></span>


            </div>
          </div>


          <br />
          <BasicOTPComponent />

          <div className="otp-text" >
            <div>  <span style={{ color: '#7F8487' }}>{translations.didntrecievedotp}</span>
              <span className="resend" style={{ float: 'right', color: '#FF0000' }} onClick={handleSendOtp}  >        {otploader ? 'Sending OTP...' : translations.resend}
              </span>
            </div>

            <div style={{ color: 'black', textAlign: 'center', color: "red", marginTop: '20px' }}>{invalidotp}</div>

            {
              loading ?
                (

                  loading &&
                  <button className="btn btn-primary" type="button" disabled style={ButtonStyleotp}>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
                    Loading...
                  </button>

                ) : (

                  <button
                    onClick={confirmbooking}
                    disabled={!onbutton}
                    type="button"
                    className="btn btn-primary send-otp "
                    style={{ marginTop: '53px' }}
                  >
                    {translations.confirm}
                  </button>
                )

            }

          </div>
        </div>
      );
    } else {


      return (

        <div>
          <div className="modal-title">
            <span> {translations.step} 4/4 </span>
            <span> <div style={{ paddingLeft: '37px' }}>{translations.confirm}</div> </span>
            <div style={{ marginLeft: '65px', fontSize: '20px', position: 'fixed', right: '33px' }}>
              <span onClick={onClose}><i className="fas fa-xmark"></i></span>

            </div>
          </div>
          <br></br>

          <div>

            {bookingToken != undefined && <div className="other">

              <div>  <input
                type="radio"
                checked={bookingfor === 'self'}
                onChange={() => {
                  setBookingfor('self');
                  const firstNameCookie = Cookies.get('firstname');
                  const lastNameCookie = Cookies.get('lastname');
                  const mobileCookie = Cookies.get('mobile')
                  if (firstNameCookie && lastNameCookie ) {
                    setFirstName(firstNameCookie);
                    setLastName(lastNameCookie);
                    setMobileNumber(mobileCookie);
                  }
                }}
              />
                <text> self </text>
              </div>
              <div>
                <input
                  type="radio"
                  checked={bookingfor === 'other'}
                  onChange={() => {
                    setBookingfor('other');
                    setFirstName('');
                    setLastName('');
                    setMobileNumber('');
                    setAddress('');
                  }}
                />
                <text> other </text>
              </div>
            </div>
            }

            <br></br>

            <div>
              <input
                type="text"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={translations.firstname}
                required
                disabled={bookingfor == 'self' && bookingToken != undefined} // Disable if booking is for self


              />
              <div style={{ color: 'red' }}>{isFirstNameError}</div>

            </div>  <br></br>
            <div>
              <input
                type="text"
                className='form-control'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={translations.lastname}
                required
                disabled={bookingfor == 'self' && bookingToken != undefined}

              />
            </div>       <div style={{ color: 'red' }}>{isLastNameError}</div>
            <br></br>
            <input
              type="text"
              className='form-control'
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              placeholder={translations.mobile}
              required

            />

            <div style={{ color: 'red' }} >{isMobileError}</div>
            <br></br>
            {anyRequiresAddress == true && <textarea
              rows={5}
              placeholder={translations.Address}
              className="form-control"
              style={{ height: '50px' }}
              value={address}

              onChange={(e) => setAddress(e.target.value)}
              name="address"
            />}

            {anyRequiresAddress == true && <div style={{ color: 'red' }} >{IsAddressError}</div>}

            {queue == 'pickup' && (
              <>
                <label>{translations["Upload Photo of Item List"]}</label>

                <input
                  type="file"
                  name="listImage"
                  className="form-control"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => handleFileChange(e)}
                  required
                />
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              </>
            )}

          </div>

          <br />

          {bookingToken != undefined && firstName != undefined && bookingfor == 'self' ? (

            loading ? (
              <button className="btn btn-primary" type="button" disabled style={ButtonStyleotp}>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
                Loading...
              </button>
            ) :
              (<button
                type="button"
                className="btn btn-primary send-otp"
                onClick={confirmbooking}
              >
                Confirm Booking
              </button>
              )) : (

            otploader ? (
              <button className="btn btn-primary" type="button" disabled style={ButtonStyleotp}>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
                Loading...
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary send-otp"
                onClick={handleSendOtp}
              >
                {translations.otp}
              </button>
            )
          )}

        </div>
      );
    }

  };


  return (

    <div className={`custom-modale ${otpSent ? "show" : ""}`}>
      <div className="modal-body">{renderContent()}</div>
    </div>

  );
};


export default LoginModal;
